import React, {useEffect, useState} from 'react';
import {Box, Grid, Button, TextField} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import auth, {saveLogin} from 'common/reducer/auth';
import {useNavigate} from 'react-router-dom';
import HeaderView from 'view/common/MobileHeader';
import {HistoryTable} from 'view/mypage/MobileHistoryTable';
import APIUtils from 'common/utils/APIUtils';
import {
  mypageBox1,
  mypageBox2,
  mypageWholePage,
} from 'view/mypage/MobileStyles';
import FooterView from 'view/common/MobileFooter';
import FilePopup from 'view/mobilepopup/FileUpload';
import DeleteHistoryPopup from 'view/mobilepopup/DeleteHistoryConfirm';
import {StringUtils} from 'common/utils/StringUtils';

const MyPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [totalEvalCount, setTotalEvalCount] = useState(0);
  const [originalHistoryTable, setOriginalHistoryTable] = useState([]);
  const [historyTable, setHistoryTable] = useState([]);
  const [groupName, setGroupName] = useState('');

  const [searchText, setSearchText] = useState('');
  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
    historyTableFilter(inputVal);
  };

  const [passStatus, setPassStatus] = useState('none');
  const [passEndTime, setPassEndTime] = useState('');
  const [passStudentCount, setPassStudentCount] = useState(0);

  //fileupload popup values
  const [fileVisibility, setFileVisibility] = useState(false);
  function fileCancel() {
    setFileVisibility(false);
  }

  //delete history confirm popup values
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [deleteEvalID, setDeleteEvalID] = useState(0);
  function deleteCancel() {
    setDeleteVisibility(false);
  }

  function deleteHistory() {
    const deleteHistory = async () => {
      try {
        const response = await APIUtils.EvalHistoryDelete(deleteEvalID);
      } catch (err) {
        console.log(err);
      }
    };

    const getHistory = async () => {
      try {
        const response = await APIUtils.EvalHistory(authReducer.user_id);
        setOriginalHistoryTable(response.data.eval_history);
      } catch (err) {
        console.log(err);
      }
    };
    deleteHistory()
      .then(() => {
        getHistory();
      })
      .then(() => {
        setDeleteVisibility(false);
      });
  }

  function historyTableFilter(searchValue) {
    if (originalHistoryTable == undefined || originalHistoryTable.length == 0) {
      setHistoryTable([]);
      return;
    }
    let filtered = [...originalHistoryTable];
    if (searchValue != undefined && searchValue.trim() != '') {
      filtered = filtered.filter(item => {
        return (
          (item.student_name != undefined &&
            item.student_name.includes(searchValue)) ||
          (item.title != undefined && item.title.includes(searchValue))
        );
      });
    }

    filtered.sort((a, b) => {
      const dateA = a.last_modify_time == '' ? a.eval_time : a.last_modify_time;
      const dateB = b.last_modify_time == '' ? b.eval_time : b.last_modify_time;
      return new Date(dateB) - new Date(dateA);
    });
    setHistoryTable(filtered);
  }

  function makePDF(eval_id) {
    const makePDFApi = async () => {
      try {
        const response = await APIUtils.CreateEvalReportUrl(eval_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    makePDFApi().then(() => {
      getHistoryInfo();
    });
  }

  function sendPDF(pdf_url) {
    window.open(pdf_url);
  }
  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setPassStatus(response.data.status);
          setPassEndTime(response.data.end_time);
          setPassStudentCount(response.data.max_student_count);
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function showTicketInfo() {
    if (passStatus == 'none') {
      return (
        <Box
          style={{
            color: '#87929d',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            marginTop: '0.2rem',
          }}
        >
          KEEwiT 이용권 미결제
        </Box>
      );
    }
    let statusText = '그룹';
    if (passStatus == 'individual') {
      statusText = '개인';
    } else if (passStatus == 'free') {
      statusText = '무료';
    }
    return (
      <Box
        style={{
          color: '#87929d',
          fontSize: '0.875rem',
          fontWeight: 'bold',
          marginTop: '0.2rem',
        }}
      >
        {'[' + statusText + '] ' + passStudentCount + '명'}
        <span style={{color: '#ff9900', marginLeft: '0.2rem'}}>
          {'~' + StringUtils.getDateKr(passEndTime)}
        </span>
      </Box>
    );
  }
  function getGroupInfo() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.GroupInfo(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setGroupName(res.data.group_name);
      }
    });
  }
  function getHistoryInfo() {
    const getHistory = async () => {
      try {
        const response = await APIUtils.EvalHistory(authReducer.user_id);
        setOriginalHistoryTable(response.data.eval_history);
      } catch (err) {
        console.log(err);
      }
    };
    getHistory();
  }
  function getLoginInfo() {
    const accountInfo = async () => {
      try {
        const response = await APIUtils.AccountInfo(authReducer.user_id);
        if (response.data.ret_code == 1000) {
          dispatch(
            saveLogin({
              user_id: response.data.user_id,
              user_name: response.data.user_name,
              user_email: response.data.email,
              export_name: response.data.export_name,
              export_email: response.data.export_email,
              grade_in_charge: response.data.grade_in_charge,
              path: authReducer.path,
              user_token: authReducer.user_token,
              group_name: response.data.group_name,
              is_group_admin: response.data.is_group_admin,
              auth_key: response.data.auth_key,
            }),
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
    accountInfo();
  }
  useEffect(() => {
    getLoginInfo();
    getHistoryInfo();
    getGroupInfo();
    getPassInfo();
  }, [fileVisibility]);
  useEffect(() => {
    historyTableFilter(searchText);
    const pdfTimer = setInterval(() => {
      const makingCount = historyTable.filter(
        val => val.last_pdf_url == 'making',
      ).length;
      getHistoryInfo();
      if (makingCount == 0) {
        clearInterval(pdfTimer);
      }
    }, 10000);
    return () => {
      clearInterval(pdfTimer);
    };
  }, [originalHistoryTable]);

  return (
    <Box style={mypageWholePage}>
      <FilePopup visibility={fileVisibility} cancelEvent={fileCancel} />
      <DeleteHistoryPopup
        visibility={deleteVisibility}
        completeEvent={deleteHistory}
        cancelEvent={deleteCancel}
      />
      <HeaderView />
      <Box style={mypageBox1}>
        <Box
          style={{fontSize: '0.8rem', marginTop: '0.3rem', color: '#717171'}}
        >
          {groupName == '' ? '(그룹 없음)' : groupName}
        </Box>
        <Box
          style={{fontSize: '1.7rem', fontWeight: 'bold', marginTop: '0.5rem'}}
        >
          {authReducer.export_name}
          {' 님'}
        </Box>
        <Box
          style={{
            color: '#68737d',
            fontSize: '0.8rem',
            fontWeight: 'normal',
            gap: '0.5rem',
            display: 'flex',
            marginTop: '0.5rem',
          }}
        >
          <Box> {authReducer.user_email}</Box>
          <Box>{'|'}</Box>
          <Box>
            {'인증 번호: '}{' '}
            {authReducer.auth_key == '' || authReducer.auth_key == undefined
              ? '0000'
              : authReducer.auth_key.substring(0, 4)}{' '}
            {'-'}
            {authReducer.auth_key == '' || authReducer.auth_key == undefined
              ? '0000'
              : authReducer.auth_key.substring(4, 8)}
          </Box>
        </Box>
        {showTicketInfo()}
      </Box>

      <Box style={mypageBox2}>
        <Box style={{fontSize: '1.6rem', fontWeight: 'bold', color: '#717171'}}>
          개별 평가
          <Button
            style={{
              paddingLeft: '0.8rem',
              paddingRight: '0.8rem',
              color: 'orange',
              // backgroundColor: 'white',
              borderRadius: '15px',
              border: '2px solid orange',
              marginLeft: '1rem',
              backgroundColor: '#FFF5F0',
              fontSize: '0.7rem',
            }}
            onClick={() => setFileVisibility(true)}
          >
            파일 업로드
          </Button>
        </Box>
        <Box
          style={{
            color: '#717171',
            fontSize: '0.8rem',
            marginTop: '1rem',
            whiteSpace: 'pre-wrap',
          }}
        >
          {'AI가 자동 평가/첨삭한 글입니다. ‘수정하기’는 PC에서만 제공합니다.'}
        </Box>
        <Box
          style={{
            color: 'black',
            fontSize: '0.7rem',
            marginTop: '1rem',
            paddingTop: '0.7rem',
            paddingBottom: '0.7rem',
            paddingRight: '5%',
            backgroundColor: '#FFE08F',
            width: '95%',
            textAlign: 'end',
          }}
        >
          <TextField
            autoComplete="off"
            size="small"
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '0px solid black',
                },
                '&:hover fieldset': {
                  border: '0px solid black',
                },
                '&.Mui-focused fieldset': {
                  border: '0px solid black',
                },
              },
            }}
            label={searchText == '' ? '🔍 검색(제목/이름)' : ''}
            InputLabelProps={{
              style: {
                color: 'rgba(108, 108, 108, 0.721)',
                fontSize: '0.5rem',
              },
              shrink: false,
            }}
            InputProps={{style: {fontSize: '0.5rem'}}}
            value={searchText}
            onChange={handleSearchText}
          ></TextField>
          <Box>※ 나의 평가는 최대 1년까지 저장됩니다.</Box>
        </Box>

        <HistoryTable
          history={historyTable}
          makeEvent={makePDF}
          sendEvent={sendPDF}
          deleteEvent={itemID => {
            setDeleteEvalID([itemID]);
            setDeleteVisibility(true);
          }}
          disabled={totalEvalCount <= 0}
        />
      </Box>
      <FooterView />
    </Box>
  );
};

export default MyPage;
