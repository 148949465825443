import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';

const SuccessPage = () => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [effectCalled, setEffectCalled] = useState(false);
  function purchaseItem(
    user_id,
    item_id,
    paymentKey,
    orderId,
    amount,
    institutionName,
  ) {
    const purchaseResult = async (
      user_id,
      item_id,
      paymentKey,
      orderId,
      amount,
    ) => {
      try {
        const response = await APIUtils.PassBuy(
          user_id,
          item_id,
          'toss',
          paymentKey,
          orderId,
          amount,
          institutionName,
        );

        if (response.data.ret_code == 1000) {
          navigate('/mypage');
        }
      } catch (err) {
        console.log(err);
      }
    };

    purchaseResult(user_id, item_id, paymentKey, orderId, amount);
  }
  useEffect(() => {
    if (effectCalled) {
      return;
    }
    setEffectCalled(true);
    const itemId = new URL(window.location.href).searchParams.get('itemId');
    const institutionName = new URL(window.location.href).searchParams.get(
      'institutionName',
    );
    const paymentKey = new URL(window.location.href).searchParams.get(
      'paymentKey',
    );
    const orderId = new URL(window.location.href).searchParams.get('orderId');
    const amount = new URL(window.location.href).searchParams.get('amount');

    if (itemId == 50000) {
      purchaseItem(1, itemId, paymentKey, orderId, amount, institutionName);
    } else {
      purchaseItem(
        authReducer.user_id,
        itemId,
        paymentKey,
        orderId,
        amount,
        institutionName,
      );
    }
  }, []);
  return <Box></Box>;
};
export default SuccessPage;
