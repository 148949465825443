import {Box, Button} from '@mui/material';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import FragmentAccountTab1 from 'view/mypage/FragmentAccountTab1';
import FragmentAccountTab2 from 'view/mypage/FragmentAccountTab2';
import FragmentAccountTab3 from 'view/mypage/FragmentAccountTab3';
import FragmentAccountTab4 from 'view/mypage/FragmentAccountTab4';
export const FragmentView = props => {
  const [tabStatus, setTabStatus] = useState(4);

  function showTab() {
    switch (tabStatus) {
      case 1:
        return <FragmentAccountTab1 getPassInfo={props.getPassInfo} />;
      case 2:
        return <FragmentAccountTab2 getPassInfo={props.getPassInfo} />;
      case 3:
        return <FragmentAccountTab3 passStatus={props.passStatus} />;
      case 4:
        return (
          <FragmentAccountTab4
            getPassInfo={props.getPassInfo}
            passStatus={props.passStatus}
            group_name={props.group_name}
            auth_key={props.auth_key}
            user_id={props.user_id}
          />
        );
      default:
        return <FragmentAccountTab1 />;
    }
  }
  return (
    <Box>
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>계정 관리</Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box
        style={{color: '#87929D', fontSize: '0.875rem', marginBottom: '2rem'}}
      >
        정보를 변경하면 즉시 반영됩니다. 그룹 나오기를 해도 학생들과의 관계가
        유지됩니다.
      </Box>
      <Box style={{display: 'flex'}}>
        <Button
          style={{
            color: tabStatus == 4 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 4 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(4);
          }}
        >
          키위런 계정 생성
        </Button>
        <Button
          style={{
            color: tabStatus == 1 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 1 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(1);
          }}
        >
          개인 정보
        </Button>

        <Button
          style={{
            color: tabStatus == 2 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 2 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(2);
          }}
        >
          그룹 이용권 현황
        </Button>
        <Button
          style={{
            color: tabStatus == 3 ? 'black' : '#ADB4BA',
            borderBottom:
              tabStatus == 3 ? '1px solid black' : '1px solid #F4F6F6',
            borderRadius: 0,
            padding: '0.5rem 2rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setTabStatus(3);
          }}
        >
          개인 결제 내역
        </Button>
      </Box>
      {showTab()}
    </Box>
  );
};
FragmentView.propTypes = {
  getPassInfo: PropTypes.func,
  passStatus: PropTypes.string,
  group_name: PropTypes.string,
  auth_key: PropTypes.string,
  user_id: PropTypes.string,
};
export default FragmentView;
