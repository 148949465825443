import PropTypes from 'prop-types';
import {Box, Checkbox, IconButton, Button} from '@mui/material';
import React, {useState, useEffect} from 'react';
import PencilIcon3 from 'asset/imageV2/icon_pencil3.svg';
import TaskEvalView3 from 'view/mypage/TaskEvalView3';
import TaskEvalView4 from 'view/mypage/TaskEvalView4';
import {useNavigate} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';

const StudentTableItem = props => {
  const task_view_list = props.task_view_list;
  const isTaskViewVisible = task_view_list.includes(props.student_id);
  const [viewType, setViewType] = useState(0);
  const [timeSortOrder, setTimeSortOrder] = useState(0);
  const [scoreSortOrder, setScoreSortOrder] = useState(0);
  const [titleSortOrder, setTitleSortOrder] = useState(0);
  const [taskNameSortOrder, setTaskNameSortOrder] = useState(0);
  const [evalTrySortOrder, setEvalTrySortOrder] = useState(0);
  const [textLengthSortOrder, setTextLengthSortOrder] = useState(0);
  const [studentSubmitList, setStudentSubmitList] = useState([]);
  const [studentSubmitListCount, setStudentSubmitListCount] = useState(0);
  const [studentOriginalSubmitList, setOriginalStudentSubmitList] =
    useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  function getStudentSubmitList(teacher_id, student_id) {
    const submitList = async () => {
      try {
        const response = await APIUtils.TaskStudentSubmitList(
          teacher_id,
          student_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          console.log('getStudentSubmitList OK ' + response.data.submit_list);
          setOriginalStudentSubmitList(response.data.submit_list);
          setStudentSubmitList(response.data.submit_list);
          setStudentSubmitListCount(response.data.submit_list.length);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    console.log(
      'CALL getStudentSubmitList : ' + teacher_id + ', ' + student_id,
    );
    if (studentOriginalSubmitList == null) submitList(teacher_id, student_id);
  }
  function sortStudentSubmitListBy(key) {
    let sortedList;

    if (key === 'submit_time') {
      sortedList = [...studentOriginalSubmitList].sort((a, b) =>
        timeSortOrder === 0
          ? new Date(a.submit_time) - new Date(b.submit_time)
          : new Date(b.submit_time) - new Date(a.submit_time),
      );
      setTimeSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'score') {
      sortedList = [...studentOriginalSubmitList].sort((a, b) =>
        scoreSortOrder === 0 ? b.score - a.score : a.score - b.score,
      );
      setScoreSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'title') {
      sortedList = [...studentOriginalSubmitList].sort((a, b) =>
        titleSortOrder === 0
          ? a.eval_result.title.localeCompare(b.eval_result.title)
          : b.eval_result.title.localeCompare(a.eval_result.title),
      );
      setTitleSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'length') {
      sortedList = [...studentOriginalSubmitList].sort((a, b) =>
        textLengthSortOrder === 0
          ? a.eval_result.전체글자수 - b.eval_result.전체글자수
          : b.eval_result.전체글자수 - a.eval_result.전체글자수,
      );
      setTextLengthSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'try') {
      sortedList = [...studentOriginalSubmitList].sort((a, b) =>
        evalTrySortOrder === 0
          ? a.eval_try - b.eval_try
          : b.eval_try - a.eval_try,
      );
      setEvalTrySortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'task') {
      sortedList = [...studentOriginalSubmitList].sort((a, b) => {
        if (a.task_id === b.task_id) {
          // task_id가 같은 경우 eval_try를 기준으로 정렬
          return taskNameSortOrder === 0
            ? a.eval_try - b.eval_try
            : b.eval_try - a.eval_try;
        }
        // task_id가 다른 경우 task_id를 기준으로 정렬
        return taskNameSortOrder === 0
          ? a.task_id - b.task_id
          : b.task_id - a.task_id;
      });

      // 정렬 순서 변경
      setTaskNameSortOrder(prev => (prev === 0 ? 1 : 0));
    }

    if (sortedList) {
      setStudentSubmitList(sortedList);
    }
  }

  function clickResult(student_id, task_id) {
    // 새 창에 URL과 쿼리 문자열 포함
    const url = `/submit?tx=${task_id}&sx=${student_id}`;
    console.log(url);
    window.open(url, '_blank', 'noopener, noreferrer');
  }

  // 공통 버튼 스타일
  const buttonStyle = {
    fontSize: '0.875rem',
    borderRadius: '1.925rem',
    padding: '0.5rem 1rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid #68737D',
          paddingBottom: '0.5rem',
          paddingTop: '0.5rem',
        }}
      >
        <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
          <Checkbox
            checked={props.checked}
            onChange={() => {
              props.handleCheck(props.index);
            }}
            inputProps={{'aria-label': 'controlled'}}
            style={{color: '#35D2BB'}}
          />

          <Box
            style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}
          >
            <Box
              style={{
                color: props.student_status == 1 ? '#cdcdcd' : 'black',
                fontSize: '1rem',
                fontWeight: 'bold',
              }}
            >
              {props.student_name}
              <span
                style={{
                  display: props.student_status == 1 ? '' : 'none',
                  fontSize: '0.7rem',
                  marginLeft: '0.5rem',
                }}
              >
                (탈퇴한 유저)
              </span>
              <span
                style={{
                  color: '#636C73',
                  fontSize: '0.875rem',
                  marginLeft: '0.5rem',
                }}
              >
                {props.student_email}
              </span>
            </Box>
            <Box
              style={{
                color: '#636C73',
                fontSize: '0.875rem',
                display: 'flex',
                gap: '0.3rem',
              }}
            >
              {props.student_class_list
                .sort((a, b) => {
                  if (a > b) return 1;
                  if (a < b) return -1;
                  return 0;
                })
                .map((value, index) => {
                  let comma =
                    index + 1 == props.student_class_list.length ? '' : ', ';
                  return <Box key={'className' + index}>{value + comma}</Box>;
                })}
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            marginLeft: '3rem',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              color: '#636C73',
              fontSize: '0.875rem',
            }}
          >
            {props.student_memo}
            <IconButton
              style={{
                width: '1rem',
                height: '1rem',
              }}
              onClick={() => {
                props.startModifyEvent();
              }}
            >
              <img
                src={PencilIcon3}
                style={{
                  width: '1rem',
                  marginLeft: '3rem',
                  height: '1rem',
                }}
                alt="PencilIcon3"
              />
            </IconButton>
          </Box>
        </Box>
        <Box
          style={{
            display: props.student_access_path == 10 ? 'flex' : 'none',
            // display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            style={{
              color: '#35D2BB',
              fontSize: '0.875rem',
              border: '1px solid #35D2BB',
              borderRadius: '1.925rem',
              padding: '0.5rem 1rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              props.resetPWEvent(props.student_id, props.student_email);
            }}
          >
            비밀번호 재설정
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!isTaskViewVisible ? (
            <Button
              style={{
                marginLeft: '2rem',
                color: '#00C500',
                fontSize: '0.875rem',
                border: '1px solid #00C500',
                borderRadius: '1.925rem',
                padding: '0.5rem 1rem',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                props.updateTaskViewList('add', props.student_id);
                getStudentSubmitList(props.teacher_id, props.student_id);
              }}
            >
              ▽ 제출한 과제 보기
            </Button>
          ) : (
            <Button
              style={{
                marginLeft: '2rem',
                color: '#FF9900',
                fontSize: '0.875rem',
                border: '1px solid #FF9900',
                borderRadius: '1.925rem',
                padding: '0.5rem 1rem',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                props.updateTaskViewList('remove', props.student_id);
              }}
            >
              ▲ 제출한 과제 접기
            </Button>
          )}
        </Box>
      </Box>
      <Box
        style={{
          display: isTaskViewVisible ? '' : 'none',
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
      >
        <Box
          style={{
            display: studentSubmitListCount === 0 ? '' : 'none',
            marginTop: '1rem',
          }}
        >
          제출한 과제가 없습니다.
        </Box>
        <Box>
          <Box
            style={{
              display: studentSubmitListCount === 0 ? 'none' : '',
              color: '#279EFF',
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            제출한 과제 : {studentSubmitListCount} 건
            <Button
              style={{
                color: '#279EFF',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #279EFF)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '2rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                width: '6rem',
              }}
              onClick={() => {
                viewType === 0 ? setViewType(1) : setViewType(0);
              }}
            >
              {viewType === 1 ? '카드보기' : '리스트보기'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #00C500)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                sortStudentSubmitListBy('task');
              }}
            >
              {'과제순'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #00C500)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                sortStudentSubmitListBy('submit_time');
              }}
            >
              {'제출순'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #00C500)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                sortStudentSubmitListBy('score');
              }}
            >
              {'점수순'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #00C500)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                sortStudentSubmitListBy('try');
              }}
            >
              {'수정횟수'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #00C500)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                sortStudentSubmitListBy('title');
              }}
            >
              {'제목순'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #00C500)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                sortStudentSubmitListBy('length');
              }}
            >
              {'글자수'}
            </Button>
          </Box>

          <Box
            style={{
              display: viewType === 0 ? 'flex' : '', // Flexbox 레이아웃
              flexWrap: viewType === 0 ? 'wrap' : '', // 항목이 넘치면 다음 줄로 감싸도록 설정
              gap: '1rem', // 항목 간 간격 설정
              justifyContent: 'center', // 가운데 정렬
            }}
          >
            {studentSubmitList.map((value, index) => {
              return viewType === 0 ? (
                <TaskEvalView3
                  key={'taskEval' + index}
                  index={index + 1}
                  submitTask={value}
                  resultEvent={clickResult}
                />
              ) : (
                <TaskEvalView4
                  key={'taskEval' + index}
                  index={index + 1}
                  submitTask={value}
                  resultEvent={clickResult}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default StudentTableItem;
StudentTableItem.propTypes = {
  student_id: PropTypes.number.isRequired,
  student_class_list: PropTypes.array.isRequired,
  student_email: PropTypes.string.isRequired,
  student_name: PropTypes.string.isRequired,
  student_memo: PropTypes.string,
  student_access_path: PropTypes.number,
  student_status: PropTypes.number,
  task_view_list: PropTypes.array.isRequired,
  handleCheck: PropTypes.func.isRequired,
  score: PropTypes.number,
  teacher_id: PropTypes.number,
  index: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  startModifyEvent: PropTypes.func.isRequired,
  resetPWEvent: PropTypes.func.isRequired,
  updateTaskViewList: PropTypes.func.isRequired, // 부모 컴포넌트에서 전달받아야 함
};
