import React, {useState} from 'react';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import SidebarView from 'view/admin/SidebarView';
import FragmentHome from 'view/admin/FragmentHome';
import FragmentTeacher from 'view/admin/FragmentTeacher';
import FragmentIDGen from 'view/admin/FragmentIDGen';
import APIUtils from 'common/utils/APIUtils';

const MyPage = () => {
  const [fragmentStatus, setFragmentStatus] = useState(1);
  const [passStatus, setPassStatus] = useState('none');
  const [passEndTime, setPassEndTime] = useState('');
  const [passStudentCount, setPassStudentCount] = useState(0);
  const authReducer = useSelector(state => state.authReducer);
  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setPassStatus(response.data.status);
          setPassEndTime(response.data.end_time);
          setPassStudentCount(response.data.max_student_count);
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function showFragment() {
    switch (fragmentStatus) {
      case 1:
        return <FragmentHome />;
      case 2:
        return <FragmentTeacher />;
      case 3:
        return (
          <FragmentIDGen
            getPassInfo={getPassInfo}
            passStatus="none"
            group_name="미상"
            auth_key="0000000"
            user_id="1002"
          />
        );

      default:
        return <FragmentHome />;
    }
  }
  function SidebarClickEvent(option) {
    setFragmentStatus(option);
  }

  return (
    <Box
      style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderView />
      <Box
        style={{
          marginLeft: '1%',
          width: '99%',
          flex: 1,
          display: 'flex',
        }}
      >
        <Box style={{backgroundColor: 'white', paddingTop: '1%'}}>
          <SidebarView
            currentFragment={fragmentStatus}
            clickEvent={SidebarClickEvent}
          />
        </Box>
        <Box
          style={{
            flex: 1,
            paddingTop: '2%',
            paddingLeft: '1%',
            paddingRight: '1%',
            backgroundColor: '#f9f9f9',
            paddingBottom: '2%',
          }}
        >
          {showFragment()}
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};

export default MyPage;
