import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TaskEvalView = props => {
  const word_cloud = props.submitTask.eval_result.word_cloud || [];
  const topWord = word_cloud.sort((a, b) => b.value - a.value).slice(0, 7);
  function textCopy(taskDB) {
    const text = taskDB.eval_result.originalText || ''; // currentText가 정의되지 않으면 빈 문자열 사용
    const title = taskDB.eval_result.title || '';
    const taskName = taskDB.task_name || '';
    const submitDate = new Date(taskDB.submit_time);
    const formattedDate =
      submitDate.getFullYear() +
      '년 ' +
      String(submitDate.getMonth() + 1).padStart(2, '0') +
      '월 ' +
      String(submitDate.getDate()).padStart(2, '0') +
      '일';
    const copyText =
      '과제 : ' +
      taskName +
      '\n날짜 : ' +
      formattedDate +
      '\n제목 : ' +
      title +
      '\n본문 : \n' +
      text +
      '\n';
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          alert('글이 복사되었습니다.'); // props 대신 alert로 결과 알림
        })
        .catch(() => {
          alert('복사를 다시 시도해주세요.');
        });
    } else {
      // 대체 흐름: execCommand 사용
      if (!document.queryCommandSupported('copy')) {
        alert('복사하기가 지원되지 않는 브라우저입니다.');
        return;
      }
      const textarea = document.createElement('textarea');
      textarea.value = copyText;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        alert('글이 복사되었습니다.');
      } catch (err) {
        alert('복사를 다시 시도해주세요.');
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.9rem',
        margin: '0.5rem 0',
        border: '1px solid #E6FAE6',
        borderRadius: '8px',
        backgroundColor: '#EBFBF9',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* 왼쪽 텍스트 섹션 */}
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '0.2rem',
        }}
      >
        {/* 항목 번호 및 제목 */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            color: '#898989',
            fontSize: '0.8rem',
            fontWeight: 'bold',
          }}
        >
          <div>
            [{props.index}]{' '}
            {StringUtils.getDateTimeKr(props.submitTask.submit_time)} (
            {props.submitTask.score}점 | {props.submitTask.eval_try}번 수정 |{' '}
            {props.submitTask.eval_result.전체글자수}글자,{' '}
            {props.submitTask.eval_result.전체문장수}문장,{' '}
            {props.submitTask.eval_result.전체문단수}문단 | 주제:{' '}
            {props.submitTask.eval_result.trait_6_score}, 구성:{' '}
            {props.submitTask.eval_result.trait_5_score}, 문장:{' '}
            {props.submitTask.eval_result.trait_4_score}, 표현:{' '}
            {props.submitTask.eval_result.trait_3_score}, 어휘:{' '}
            {props.submitTask.eval_result.trait_2_score}, 문법:{' '}
            {props.submitTask.eval_result.trait_1_score})
          </div>
        </Box>

        {/* 평가 제목 및 세부 정보 */}
        <Box>
          <Box
            style={{
              fontSize: '1rem',
              color: '#262C31',
              fontWeight: 'bold',
            }}
          >
            {props.submitTask.title + ' '}
            <font
              style={{
                fontSize: '0.8rem',
                color: '#279EFF',
              }}
            >
              (
              {topWord.map((word, index) => (
                <span key={index}>
                  {word.text}
                  {index < topWord.length - 1 ? ', ' : ''}
                </span>
              ))}
              )
            </font>
            {' / ' + props.submitTask.task_name + ' '}
          </Box>
        </Box>
      </Box>

      {/* 버튼 섹션 */}
      <Box>
        <Button
          style={{
            color: 'white',
            fontSize: '0.875rem',
            border: 'none',
            borderRadius: '1.925rem',
            padding: '0.5rem 1rem',
            backgroundColor: '#4FD7C3',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
          }}
          onClick={() => {
            props.resultEvent(
              props.submitTask.student_id,
              props.submitTask.task_id,
            );
          }}
        >
          보기
        </Button>
        <Button
          style={{
            color: '#898989',
            fontSize: '0.875rem',
            border: '1px solid #898989',
            borderRadius: '1.925rem',
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            marginLeft: '1rem',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
          }}
          onClick={() => {
            textCopy(props.submitTask);
          }}
        >
          복사
        </Button>
      </Box>
    </Box>
  );
};

TaskEvalView.propTypes = {
  index: PropTypes.number,
  submitTask: PropTypes.object.isRequired,
  resultEvent: PropTypes.func.isRequired,
};

export default TaskEvalView;
