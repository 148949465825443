import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import TicketView2 from 'view/mypage/TicketView2';
import PropTypes from 'prop-types';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const [purchaseTable, setPurchaseTable] = useState([]);

  async function getTossReceipt(pKey) {
    const getTossItemInfo = async pay_key => {
      try {
        const response = await APIUtils.TossItem(pay_key);
        return response.data.receipt.url;
      } catch (err) {
        return '';
      }
    };

    return await getTossItemInfo(pKey);
  }

  useEffect(() => {
    const purchaseHistory = async () => {
      try {
        const response = await APIUtils.PassList(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const tossReceipt = async table => {
      try {
        const response = await table.map(async val => {
          if (val.payment_key == '') {
            val['receipt'] = '';
            return val;
          }
          val['receipt'] = await getTossReceipt(val.payment_key);
          return val;
        });
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    purchaseHistory().then(res => {
      setPurchaseTable(res.data.pass_list);
    });
    // purchaseHistory()
    //   .then(res => {
    //     return tossReceipt(res.data.pass_list);
    //   })
    //   .then(r => {
    //     const historyPromise = r.map(async item => {
    //       return await item;
    //     });
    //     Promise.all(historyPromise).then(table => {
    //       setPurchaseTable(table);
    //     });
    //   });
  }, []);
  return (
    <Box style={{marginTop: '3rem'}}>
      {purchaseTable.map((val, idx) => {
        return (
          <TicketView2
            key={'pass_view' + idx}
            ticket_name={val.pass_name}
            start_time={val.pass_start_time}
            end_time={val.pass_end_time}
            receipt={val.receipt ?? ''}
          />
        );
      })}
    </Box>
  );
};
FragmentView.propTypes = {
  passStatus: PropTypes.string,
};
export default FragmentView;
