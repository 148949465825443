import React, {useEffect, useState} from 'react';
import {Box, Grid} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import {NumberUtils} from 'common/utils/NumberUtils';
import {StringUtils} from 'common/utils/StringUtils';
import {
  MarginBoxComponent,
  WhiteBox,
  TitleComponent2,
  SingleLineTitle,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiViewLoading';
import CommentItem from 'view/comment2/CommentItem';
import HeaderView from 'view/common/Header';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import FooterView from 'view/common/Footer';
import RadarChart from 'view/common/RadarChart';
import KeewiCloud from 'view/common/KeewiCloud';

import {
  categoryTextBox,
  overallBox,
  overallLeftBox,
  overallTextBox,
} from 'view/result/Styles';

const ResultViewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [commentParagraph, setCommentParagraph] = useState([]);

  const [loading, setLoading] = useState(true);

  const [titleText, setTitleText] = useState('');
  const [nameText, setNameText] = useState('');
  const [taskNameText, setTaskNameText] = useState('');
  const [labelText, setLabelText] = useState('');
  const [analyText, setAnalyText] = useState('');
  const [dateText, setDateText] = useState('');

  const [tScore, setTScore] = useState(0);
  const [keewiScore, setKeewiScore] = useState({
    겹치는단어비율: 0,
    단어의평균길이: 0,
    문단별문장수: 0,
    문장별단어수: 0,
    분석오류문장수: 0,
    전체글자수: 0,
    전체문장수: 0,
    전체문단수: 0,
    흐름이자연스러운정도: 0,
    긴문장의수: 0,
    독자나이: 0,
  });
  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: [],
  });

  const [wordCloudData, setWordCloudData] = useState([
    {text: '키위', value: 1},
    {text: '글쓰기', value: 2},
    {text: '평가', value: 3},
    {text: '엔진', value: 4},
    {text: '피드백', value: 5},
  ]);

  const [overallComment, setOverallComment] = useState('');
  const [isGPTText, setisGPTText] = useState(false);

  function showGPTValidation() {
    if (isGPTText) {
      return (
        <Box
          style={{
            backgroundColor: '#f5d7da',
            color: '#bb0000',
            fontSize: '1rem',
            padding: '0.5rem',
          }}
        >
          ⚠ GPT로 생성한 글일 가능성이 높습니다.
        </Box>
      );
    }
  }
  function setAnalysisInfoFromKeewiApiLoad(res) {
    setTitleText(res.data.eval_result.title);
    setNameText(res.data.eval_result.student_name);
    setTaskNameText(res.data.task_name);
    setTScore(NumberUtils.getAnalysisNumber(res.data.eval_result.score));
    setDateText(res.data.submit_time);
    const keewiScore = {
      겹치는단어비율: res.data.eval_result.겹치는단어비율,
      단어의평균길이: res.data.eval_result.단어의평균길이,
      문단별문장수: res.data.eval_result.문단별문장수,
      문장별단어수: res.data.eval_result.문장별단어수,
      분석오류문장수: res.data.eval_result.분석오류문장수,
      전체글자수: res.data.eval_result.전체글자수,
      전체문장수: res.data.eval_result.전체문장수,
      전체문단수: res.data.eval_result.전체문단수,
      흐름이자연스러운정도: res.data.eval_result.흐름이자연스러운정도,
      긴문장의수: res.data.eval_result.긴문장의수 ?? 0,
      독자나이: res.data.eval_result.독자나이 ?? 10,
    };
    setKeewiScore(keewiScore);
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));

    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );
    setOverallComment(res.data.eval_result.comment);
    setisGPTText(res.data.eval_result.is_gpt_text);

    let datasets = [];
    datasets = [
      {
        data: [
          AnalysisText.getNumericScore(res.data.eval_result.trait_1_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_2_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_3_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_4_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_5_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_6_score),
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
    const wordCloudData = res.data.eval_result.word_cloud;
    setWordCloudData(wordCloudData);
  }
  function createWholeStatisticsText() {
    let flowLevel = '어려움';
    if (keewiScore.흐름이자연스러운정도 >= 71) flowLevel = '쉬움';
    else if (keewiScore.흐름이자연스러운정도 >= 63) flowLevel = '보통';
    return (
      <Box style={{width: '90%', marginBottom: '0rem'}}>
        <Box>
          {createStatisticsText('전체 글자 수', keewiScore.전체글자수, '자')}
        </Box>
        <Box>
          {createStatisticsText('전체 문단 수', keewiScore.전체문단수, '개')}
        </Box>
        <Box>
          {createStatisticsText('전체 문장 수', keewiScore.전체문장수, '개')}
        </Box>
        <Box>
          {createStatisticsText(
            '문단별 문장 수',
            keewiScore.문단별문장수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText(
            '긴 문장(50자)의 수',
            keewiScore.긴문장의수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText('예상 독자 나이', keewiScore.독자나이, '세')}
        </Box>
        <Box>{createStatisticsText('읽기 쉬운 정도', flowLevel, '')}</Box>
      </Box>
    );
  }
  function createStatisticsText(title, score, rest) {
    return (
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '0.4rem',
          fontSize: '1rem',
          fontWeight: '500',
        }}
      >
        <Box>{title}</Box>
        <Box>
          <span style={{color: '#f67f10'}}>{score}</span> {rest}
        </Box>
      </Box>
    );
  }

  const closeWindow = () => {
    window.close(); // 현재 창 닫기
  };
  function createOverallComment() {
    return (
      <Box style={overallBox}>
        <Box style={overallLeftBox}>총평</Box>
        <Box
          style={{
            backgroundColor: '#D8DCDE',
            height: '2px',
            width: '100%',
            margin: '1rem 0rem',
          }}
        ></Box>
        <Box style={overallTextBox}>{overallComment}</Box>
      </Box>
    );
  }

  useEffect(() => {
    let inputTaskID = 0;
    let inputStudentID = 0;
    let inputFromPage = '';
    if (
      location.state == null &&
      window.localStorage.getItem('task_id') == null
    ) {
      navigate('/', {replace: true});
      return;
    } else {
      inputTaskID = Number(window.localStorage.getItem('task_id'));
      inputStudentID = Number(window.localStorage.getItem('student_id'));
      inputFromPage = window.localStorage.getItem('from');
    }
    const setAnalasisResult = async (task_id, student_id) => {
      try {
        const response = await APIUtils.GetTaskSubmitResult(
          authReducer.user_id,
          task_id,
          student_id,
        );
        setAnalysisInfoFromKeewiApiLoad(response);
      } catch (err) {
        console.log(err);
      }
    };

    setAnalasisResult(inputTaskID, inputStudentID).then(r => {
      setLoading(false);
    });
    const backspace = e => {
      if (
        e.keyIdentifier == 'U+0008' ||
        e.keyIdentifier == 'Backspace' ||
        e.keyCode == 8
      ) {
        if (e.target == document.body) {
          e.preventDefault();
          if (inputFromPage == 'finished') {
            navigate('/mypage', {
              replace: true,
              state: {
                fragmentNum: 6,
                selectedTaskID: inputTaskID,
              },
            });
          }
          return false;
        }
      }
    };
    window.addEventListener('keydown', backspace, true);
    const preventGoBack = () => {
      history.pushState(null, '', location.href);
      if (inputFromPage == 'finished') {
        navigate('/mypage', {
          replace: true,
          state: {
            fragmentNum: 6,
            selectedTaskID: inputTaskID,
          },
        });
      } else {
        navigate('/mypage', {
          replace: true,
          state: {
            fragmentNum: 7,
            selectedTaskID: inputTaskID,
          },
        });
      }
    };
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', preventGoBack);

    return () => {
      window.removeEventListener('keydown', backspace, true);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TitleComponent2 text1={titleText} text2={`과제명 : ${taskNameText}`} />
      {showGPTValidation()}
      <MarginBoxComponent>
        <>
          <Box
            style={{
              display: 'flex',
              marginTop: '-6rem',
              justifyContent: 'flex-end', // 오른쪽 끝으로 정렬
            }}
          >
            <button
              style={{
                color: '#898989',
                fontWeight: 'bold',
                fontSize: '0.874rem',
                border: 'none',
                borderRadius: '1.925rem',
                padding: '0.5rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                height: '3rem',
                width: '6rem',
                marginRight: '1rem',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={closeWindow}
            >
              닫기
            </button>
            <button
              style={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '0.874rem',
                border: 'none',
                borderRadius: '1.925rem',
                padding: '0.5rem 1rem',
                backgroundColor: '#4FD7C3',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                height: '3rem',
                width: '6rem',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={() => {
                const printStyles = `
                  @media print {
                    body {
                      zoom: 1.0;
                    }
                  }
                `;
                const styleSheet = document.createElement('style');
                styleSheet.type = 'text/css';
                styleSheet.innerText = printStyles;
                document.head.appendChild(styleSheet);
                window.print();
                document.head.removeChild(styleSheet);
              }}
            >
              프린트하기
            </button>
          </Box>
          <Box
            style={{
              borderBottom: '1px solid #7d7d7d',
              marginTop: '3.5rem',
              marginBottom: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <SingleLineTitle
              title={`이름 : ${nameText} (${dateText.substring(0, 10)})`}
              tScore={tScore}
            />
          </Box>
          <Grid container spacing={'1rem'}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <WhiteBox>
                <>
                  {labelText}
                  {analyText}
                </>
              </WhiteBox>
            </Grid>
          </Grid>
          <Box style={{height: '1rem'}}></Box>
          <Grid container spacing={'1rem'}>
            <Grid
              item
              xs={7}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {createOverallComment()}
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                marginTop: '1rem',
                flexDirection: 'column',
              }}
            ></Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <RadarChart radarData={radarData} />
            </Grid>
          </Grid>
          <Box style={{height: '1rem'}}></Box>
          <WhiteBox style={{alignItems: 'center'}}>
            <Grid container spacing={'1rem'}>
              <Grid
                item
                xs={5}
                style={{
                  display: 'flex',
                  marginTop: '1rem',
                  flexDirection: 'column',
                }}
              >
                {createWholeStatisticsText()}
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: 'flex',
                  marginTop: '1rem',
                  flexDirection: 'column',
                }}
              ></Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <KeewiCloud data={wordCloudData} />
              </Grid>
            </Grid>
          </WhiteBox>
        </>
      </MarginBoxComponent>
    </Box>
  );
};
export default ResultViewPage;
