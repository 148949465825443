import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, Button, Tooltip} from '@mui/material';
import pdfSample from 'asset/image/image_pdf_sample.png';
import {styled} from '@mui/material/styles';
const CustomTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} componentsProps={{tooltip: {className: className}}} />
))(`
      background-color: rgba(0,0,0,0);
      justify-content: end;
      display: flex;
  `);

const TooltipItem = props => {
  return (
    <CustomTooltip
      title={<img src={pdfSample} alt={'pdfImage'} style={{width: '800px'}} />}
      placement="top-start"
    >
      <Button
        style={{
          color: props.color,
          textDecorationLine: 'underline',
          whiteSpace: 'nowrap',
          fontSize: 'calc(0.5rem + 0.3vw)',
          fontWeight: 'bold',
        }}
      >
        평가 리포트(PDF) 예제
      </Button>
    </CustomTooltip>
  );
};

TooltipItem.propTypes = {
  color: PropTypes.string,
};
export default TooltipItem;
