import {Backdrop, Button, Box} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import logo from 'asset/image/logo_keewit.png';
import CryptoJS from 'crypto-js';
import ClipboardCopy from 'view/common/MobileClipboardCopy';
import {useNavigate} from 'react-router-dom';
import {saveLogin, removeLogin} from 'common/reducer/auth';
import home from 'asset/image/icon_home.png';
import mypage from 'asset/image/icon_mypage.png';
import mysetting from 'asset/image/icon_mysetting.png';
import purchase from 'asset/image/icon_purchase.png';
import notice from 'asset/image/icon_notice.png';
import logout from 'asset/image/icon_logout.png';
import youtube from 'asset/image/icon_youtube.png';

const MobileMenu = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const isLogin = authReducer.isLogin;
  const name = authReducer.export_name;

  function getStudentLink() {
    const encryptedID = CryptoJS.AES.encrypt(
      authReducer.user_id.toString(),
      process.env.REACT_APP_AES_KEY,
    )
      .toString()
      .replaceAll('/', '$keewi$');
    const link =
      process.env.REACT_APP_BASE_URI + '/writing/student/' + encryptedID;
    return link;
  }

  function mobileTestLogin() {
    dispatch(
      saveLogin({
        user_id: 10003,
        user_name: '장준필',
        user_email: 'junphill@twoblockai.com',
        export_name: '장준필',
        export_email: 'junphill@twoblockai.com',
        grade_in_charge: '미정',
        path: 'testpath',
        user_token: 'testtoken',
      }),
    );
    navigate('/');
  }
  if (isLogin) {
    return (
      <>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'start',
          }}
          open={true}
          onClick={() => {
            props.clickOutside();
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              backgroundColor: 'white',
              width: '70%',
              height: '80%',
            }}
          >
            <Box
              style={{
                width: '84%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8%',
                // backgroundColor: '#B6E4D3',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* <img src={logo} alt={'logoT'} style={{height: '30px'}} /> */}
                <Box
                  style={{
                    color: '#717171',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                  }}
                >
                  {name + ' 선생님'}
                </Box>
              </Box>
              <ClipboardCopy
                link={getStudentLink()}
                resultEvent={props.copyEvent}
              />
            </Box>
            <Box
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: '#d9d9d9',
              }}
            />
            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'center',
                fontSize: '1.1rem',
                fontWeight: 'medium',
              }}
            >
              <Box
                style={{
                  width: '90%',
                  marginTop: '1rem',
                  color: '#717171',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/');
                }}
              >
                <img
                  src={home}
                  alt={'home'}
                  style={{
                    height: '1.2rem',
                    marginRight: '0.5rem',
                  }}
                />
                홈
              </Box>
              <Box
                style={{
                  width: '90%',
                  marginTop: '1.4rem',
                  color: '#717171',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/mypage');
                }}
              >
                <img
                  src={mypage}
                  alt={'mypage'}
                  style={{height: '1.2rem', marginRight: '0.5rem'}}
                />
                개별 평가
              </Box>
              <Box
                style={{
                  width: '90%',
                  marginTop: '1.4rem',
                  color: '#717171',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/mytask');
                }}
              >
                <img
                  src={mysetting}
                  alt={'mysetting'}
                  style={{height: '1.2rem', marginRight: '0.5rem'}}
                />
                과제 관리
              </Box>
              <Box
                style={{
                  width: '90%',
                  marginTop: '1.4rem',
                  color: '#717171',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/purchase');
                }}
              >
                <img
                  src={purchase}
                  alt={'purchase'}
                  style={{height: '1.2rem', marginRight: '0.5rem'}}
                />
                이용권 구매
              </Box>
              <Box
                style={{
                  width: '90%',
                  marginTop: '1.4rem',
                  marginBottom: '1rem',
                  color: '#717171',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/notice');
                }}
              >
                <img
                  src={notice}
                  alt={'notice'}
                  style={{height: '1.2rem', marginRight: '0.5rem'}}
                />
                공지사항
              </Box>
            </Box>
            <Box
              style={{
                marginTop: '0.5rem',
                width: '100%',
                height: '1px',
                backgroundColor: '#d9d9d9',
              }}
            />
            <Box
              style={{
                marginTop: '0.3rem',
                width: '90%',
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '5%',
              }}
            >
              <Box style={{color: '#717171', fontSize: '0.7rem'}}>
                ※ 일부 기능은{' '}
                <span style={{fontWeight: 'bold'}}>PC,태블릿에서만</span>{' '}
                지원합니다.
              </Box>
              <Box
                style={{
                  height: '100%',
                  color: '#717171',
                  fontSize: '1rem',
                  display: 'flex',
                  alignItems: 'end',
                }}
                onClick={() => {
                  dispatch(removeLogin());
                }}
              >
                <img
                  src={logout}
                  alt={'logout'}
                  style={{height: '1.1rem', marginRight: '0.5rem'}}
                />
                로그아웃
              </Box>
            </Box>
          </Box>
        </Backdrop>
      </>
    );
  }
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'start',
        }}
        open={true}
        onClick={() => {
          props.clickOutside();
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            backgroundColor: 'white',
            width: '70%',
            height: '80%',
          }}
        >
          <Box
            style={{
              width: '84%',
              display: 'flex',
              margin: '8%',
              alignItems: 'center',
            }}
          >
            <img src={logo} alt={'logoT'} style={{height: '30px'}} />
            <Box
              style={{
                color: '#717171',
                marginLeft: '1rem',
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              {'키위티'}
            </Box>
          </Box>
          <Box
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#d9d9d9',
            }}
          />
          <Box
            style={{
              marginTop: '1rem',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              style={{
                marginTop: '1rem',
                backgroundColor: '#3db49e',
                borderRadius: '10px',
                color: 'white',
                width: '80%',
                fontWeight: 'medium',
                fontSize: '1rem',
              }}
              onClick={() => {
                navigate('/login');
              }}
            >
              간편 회원가입/로그인
            </Button>
            <Button
              style={{
                marginTop: '1rem',
                color: '#3db49e',
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '1px solid #3db49e',
                width: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1rem',
              }}
              onClick={() =>
                window.open(
                  'https://keewi-t.notion.site/KEEwi-T-137c9fc669128071b5ddeb7e23237770',
                  '_blank',
                )
              }
            >
              <img
                src={youtube}
                alt={'youtube'}
                style={{height: '30px', marginRight: '0.5rem'}}
              />
              키위티 사용법
            </Button>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};

MobileMenu.propTypes = {
  clickOutside: PropTypes.func,
  copyEvent: PropTypes.func,
};

export default MobileMenu;
