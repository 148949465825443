import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';

import ApiLoading from 'view/common/ApiLoading';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import ErrorIcon from 'asset/imageV2/icon_error1.png';

const RedirectPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useSelector(state => state.authReducer);
  const isLogin = authReducer.isLogin;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const replaceResultPage = (studentID, taskID) => {
    window.localStorage.setItem('task_id', taskID);
    window.localStorage.setItem('student_id', studentID);
    window.localStorage.setItem('from', 'studentList');
    navigate('/result3', {
      state: {
        task_id: taskID,
        student_id: studentID,
        from: 'studentList',
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // URL의 쿼리 문자열 추출
        const query = new URLSearchParams(location.search);
        const task_id = query.get('tx');
        const student_id = query.get('sx');

        if (!task_id || !student_id) {
          throw new Error('유효하지 않은 URL 매개변수입니다.');
        }
        replaceResultPage(student_id, task_id);
      } catch (err) {
        console.error(err);
        setError(err.message || '알 수 없는 오류가 발생했습니다.');
      } finally {
        setLoading(false);
      }
    };

    if (isLogin) {
      fetchData();
    } else {
      navigate('/login');
    }
  }, [isLogin, navigate, location.search]);

  if (loading) return <ApiLoading loading={loading} />;

  if (error) {
    return (
      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
        }}
      >
        <img
          alt="ErrorIcon"
          src={ErrorIcon}
          height="200rem"
          width="200rem"
          style={{marginBottom: '1rem'}}
        />
        <Box style={{fontWeight: 'bold', fontSize: '1.5rem'}}>{error}</Box>
      </Box>
    );
  }

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderView />
      <Box display={{flex: 1}}>
        글이 제출 취소되었거나 비정상적인 접근입니다..
      </Box>
      <FooterView />
    </Box>
  );
};

export default RedirectPage;
