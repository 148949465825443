import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Box, IconButton, Button} from '@mui/material';
import KeewiChip from 'view/result/KeewiChip';
import {CommentBox} from 'view/common/Components';
import emojiRegex from 'emoji-regex';
// import Pencil from 'asset/image/icon_pencil.png';
// import Dashed from 'asset/image/icon_dashed.png';

const ItemView = props => {
  return (
    <Box
      style={{
        textDecorationColor: 'black',
        textDecorationThickness: '1px',
      }}
    >
      <CommentBox>
        <>
          <Box
            style={{display: 'flex', flexDirection: 'column', fontSize: '1rem'}}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
              }}
            >
              <Box>
                <span style={{color: '#FF9900', fontSize: 'bold'}}>
                  {props.commentID == -1 ? '' : '(' + props.commentID + ') '}
                </span>
                {props.A}
              </Box>
            </Box>
            <Box
              style={{
                height: '1px',
                width: '100%',
                backgroundColor: 'black',
                margin: '0.5rem 0rem',
              }}
            />
            <Box style={{color: '#FF9900'}}>→ {props.B}</Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              marginTop: '1rem',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              color: '#68737D',
            }}
          >
            <KeewiChip subtype={props.subtype} />
            <span
              style={{
                marginLeft: '0.5rem',
                whiteSpace: 'pre-wrap', // 줄바꿈 및 공백 보존
              }}
            >
              {props.C}
            </span>
          </Box>
        </>
      </CommentBox>
    </Box>
  );
};
ItemView.propTypes = {
  paragraphIndex: PropTypes.number,
  idx: PropTypes.number,
  commentID: PropTypes.number,
  A: PropTypes.string,
  B: PropTypes.string,
  C: PropTypes.string,
  subtype: PropTypes.string,
};
export default ItemView;
