import React from 'react';
import {Box, Button} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import PDFTooltip from 'view/common/PDFTooltip';
import smile from 'asset/image/icon_smile.png';
import PropTypes from 'prop-types';

const ItemView = ({
  visibility,
  cancelEvent,
  completeEvent,
  nextEvent,
  taskID,
  prevStudentID,
  nextStudentID,
  prevStudentName,
  nextStudentName,
}) => {
  if (!visibility) return null;

  const styles = {
    overlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 5,
      backgroundColor: 'rgba(55,55,55,0.5)',
    },
    container: {
      width: '28rem',
      textAlign: 'left',
      borderRadius: '20px',
      backgroundColor: 'white',
      padding: '1rem 3rem 2rem',
      color: 'black',
    },
    closeButton: {
      color: '#717171',
      fontSize: '1.5rem',
    },
    title: {
      fontSize: '1.7rem',
      fontWeight: 'bold',
      margin: '0.5rem 0 1rem',
    },
    description: {
      fontSize: '1.1rem',
      color: '#717171',
      marginTop: '0.5rem',
    },
    divider: {
      borderTop: '2px dotted #717171',
      marginTop: '1.5rem',
    },
    button: {
      marginTop: '1rem',
      backgroundColor: Palette.green8,
      color: 'white',
      fontSize: 'calc(1rem + 0.2vw)',
      fontWeight: 'bold',
      padding: '1rem',
      borderRadius: '10px',
      flex: 1,
    },
    buttonGroup: {
      display: 'flex',
      gap: '0.5rem',
    },
  };

  return (
    <Box style={styles.overlay}>
      <Box style={styles.container}>
        {/* Close Button */}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={cancelEvent}
            style={{
              color: '#898989',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              border: '1px solid #898989',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              whiteSpace: 'nowrap',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            돌아가기
          </Button>
        </Box>
        {/* Smile Icon */}
        {/* Title */}
        <Box style={styles.title}> 평가 결과를 저장했습니다.</Box>
        {/* Description */}
        <Box style={styles.description}>
          다음과 이전 제출된 결과를 이어서 평가할 수 있습니다.
        </Box>{' '}
        <Box>[과제 관리 화면]에서 완성된 평가 리포트를 공유할 수 있습니다.</Box>
        {/* PDF Tooltip */}
        <Box style={{marginTop: '0.5rem'}}>
          <PDFTooltip color="#ff6928" />
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '3rem',
          }}
        >
          <Box>
            <Button
              style={{
                whiteSpace: 'nowrap',
                color: prevStudentID ? 'white' : '#B1B1B1',
                backgroundColor: prevStudentID ? '#279EFF' : '#E9EBED',
                fontSize: '0.875rem',
                border: prevStudentID
                  ? '1px solid #279EFF'
                  : '1px solid #E9EBED',
                padding: '0.9rem 0rem',
                minWidth: '6rem',
                maxWidth: '10rem',
                borderRadius: '6.25rem',
                boxShadow: prevStudentID ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '',
              }}
              onClick={() => prevStudentID && nextEvent(taskID, prevStudentID)}
              disabled={!prevStudentID}
            >
              {prevStudentID ? `${prevStudentName}` : '이전 없음'}
            </Button>
            <Button
              style={{
                color: '#00C500',
                fontSize: '1.125rem',
                fontWeight: 'bold',
                border: '1px solid #00C500',
                borderRadius: '1.625rem',
                padding: '0.7rem 0rem',
                width: '10rem',
                marginLeft: '1rem',
                whiteSpace: 'nowrap',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={completeEvent}
            >
              과제 관리 화면
            </Button>
            <Button
              style={{
                whiteSpace: 'nowrap',
                color: nextStudentID ? 'white' : '#B1B1B1',
                backgroundColor: nextStudentID ? '#279EFF' : '#E9EBED',
                fontSize: '0.875rem',
                border: nextStudentID
                  ? '1px solid #279EFF'
                  : '1px solid #E9EBED',
                padding: '0.9rem 0rem',
                marginLeft: '1rem',
                minWidth: '6rem',
                maxWidth: '10rem',
                borderRadius: '6.25rem',
                boxShadow: nextStudentID ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '',
              }}
              onClick={() => nextStudentID && nextEvent(taskID, nextStudentID)}
              disabled={!nextStudentID}
            >
              {nextStudentID ? `${nextStudentName}` : '다음 없음'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ItemView.propTypes = {
  visibility: PropTypes.bool.isRequired,
  cancelEvent: PropTypes.func.isRequired,
  completeEvent: PropTypes.func.isRequired,
  nextEvent: PropTypes.func.isRequired,
  taskID: PropTypes.number.isRequired,
  prevStudentID: PropTypes.number.isRequired,
  nextStudentID: PropTypes.number.isRequired,
  prevStudentName: PropTypes.string.isRequired,
  nextStudentName: PropTypes.string.isRequired,
};

export default ItemView;
