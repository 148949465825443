import {Box, Button, TextField} from '@mui/material';
import React, {useState, useEffect} from 'react';
import APIUtils from 'common/utils/APIUtils';
import PropTypes from 'prop-types';
import emojiRegex from 'emoji-regex';
import ApiLoading from 'view/common/ApiLoading';

export const FragmentView = props => {
  const [groupName, setGroupName] = useState(props.group_name);
  const [ID, setID] = useState('');
  const [PW, setPW] = useState('');
  const [nameText, setNameText] = useState('');
  const [user_id, setTID] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleName = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 15) {
        inputVal = inputVal.substring(0, 15);
      }
      setNameText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const handleGroupName = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 15) {
        inputVal = inputVal.substring(0, 15);
      }
      setGroupName(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const handleID = event => {
    const inputValue = event.target.value.replace(' ', '');
    const filteredValue = inputValue
      .replace(/[^a-zA-Z0-9]/g, '')
      .substring(0, 15); // 영문자와 숫자만 남기기
    setID(filteredValue);
  };
  const handlePW = event => {
    const inputValue = event.target.value;
    const filteredValue = inputValue
      .replace(/[^a-zA-Z0-9]/g, '')
      .substring(0, 15); // 영문자와 숫자만 남기기
    setPW(filteredValue);
  };
  useEffect(() => {
    setTID(props.user_id);
  }, [props.user_id]);

  const [modifyResultMessage, setModifyResultMessage] = useState('');
  const [modifyResultColor, setModifyResultColor] = useState('');
  async function saveKeewiAccount() {
    if (groupName == '') {
      setModifyResultMessage('실패 : 그룹명을 입력해야 합니다.');
      setModifyResultColor('#FF0000');
      return;
    }
    if (nameText == '') {
      setModifyResultMessage('실패 : 사용자 이름을 입력해야 합니다.');
      setModifyResultColor('#FF0000');
      return;
    }
    if (ID == '') {
      setModifyResultMessage('실패 : ID는 5글자 이상 입력해야 합니다.');
      setModifyResultColor('#FF0000');
      return;
    }
    if (ID.length < 5) {
      setModifyResultMessage('실패 : ID는 5글자 이상 입력해야 합니다.');
      setModifyResultColor('#FF0000');
      return;
    }
    if (PW == '') {
      setModifyResultMessage('실패 : PW는 5글자 이상 입력해야 합니다.');
      setModifyResultColor('#FF0000');
      return;
    }

    if (PW.length < 5) {
      setModifyResultMessage('실패 : 비밀번호는 5글자 이상 입력해야 합니다.');
      setModifyResultColor('#FF0000');
      return;
    }
    setModifyResultMessage(
      `${ID}/${PW}로 ${nameText}에 대한 계정을 생성중입니다.`,
    );
    setModifyResultColor('green');
    setIsLoading(true); // 로딩 상태 종료
    try {
      const response = await APIUtils.createKEEwiLearnAccountApi(
        user_id,
        groupName,
        ID,
        PW,
        nameText,
      );
      if (response.data.ret_code == 1000) {
        setModifyResultMessage(
          `성공 : ${ID}/${PW}로 ${nameText}에 대한 계정이 생성되었습니다.`,
        );
        setModifyResultColor('blue');
        setNameText('');
        setID('');
        setPW('');
      } else if (response.data.ret_code == 2800) {
        setModifyResultMessage(
          `실패 : ${ID}가 이미 존재해서 생성하지 못했습니다. 새로운 ID로 시도해보세요.`,
        );
        setModifyResultColor('red');
      } else if (response.data.ret_code == 2002) {
        setModifyResultMessage(
          '성공 : ${ID}/${PW}로 ${nameText}에 대한 계정은 생성되었습니다. 그러나 학생과 연결이 실패했습니다. 학생과 연결이 필요합니다.',
        );
        setModifyResultColor('red');
      } else if (response.data.ret_code == 2701) {
        setModifyResultMessage(
          '실패 : 이용권이 초과되어 ' +
            ID +
            ' 계정은 생성되지 못했습니다. 학생 관리에서 연결된 학생을 삭제하면 여유가 생길 수 있습니다.',
        );
        setModifyResultColor('red');
      } else {
        setModifyResultMessage('실패 : 에러 코드 : ' + response.data.ret_code);
        setModifyResultColor('red');
      }
    } catch (err) {
      console.log(err);
      setModifyResultMessage(
        '실패 : 계정을 생성하지 못했습니다. 다시 시도해보세요.',
      );
      setModifyResultColor('red');
      setIsLoading(false); // 로딩 상태 종료
    } finally {
      setIsLoading(false); // 로딩 상태 종료
    }
  }

  function haveNoPass() {
    return (
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: '12rem',
          }}
        >
          <Box style={{color: '#49545C', marginTop: '0.5rem', align: 'center'}}>
            <Box
              style={{color: '#49545C', marginTop: '0.5rem', align: 'center'}}
            >
              현재 보유한 이용권이 없어서 키위런 계정 생성 기능을 사용할 수
              없습니다.
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  function makeKeewilID() {
    return (
      <Box style={{marginTop: '3rem'}}>
        <Box style={{marginTop: '1rem'}}>
          키위런 계정을 생성하면, 학생의 인증요청이 없어도 자동으로 학생 계정과
          연결되며 이용권이 사용됩니다.
        </Box>
        <Box style={{marginTop: '1rem', marginBottom: '3rem'}}>
          <font color="#4FD7C3">학생 관리 메뉴</font>
          에서 확인하실 수 있습니다.
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Box
            style={{
              marginLeft: '0rem',
              fontSize: '1.125rem', // 원래 폰트 크기로 복구
              fontWeight: 'bold',
              marginRight: '2rem',
              width: '10rem',
            }}
          >
            그룹명
          </Box>
          <Box style={{position: 'relative', width: '300px'}}>
            <TextField
              autoComplete="off"
              variant="outlined"
              label={groupName == '' ? '그룹명을 입력해 주세요.' : ''}
              InputProps={{
                style: {
                  backgroundColor: '#F7F8FA',
                  border: '1px solid #E9EBED',
                  fontSize: '1rem', // 입력 필드 글씨 크기 유지
                  borderRadius: '0.5rem', // 테두리 둥글기 조정
                  height: '2.5rem', // 필드 높이
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: '0.875rem', // 라벨 글씨 크기 유지
                  position: 'absolute', // 라벨을 절대 위치로 설정
                  top: '50%', // 중앙 정렬
                  marginLeft: '1rem',
                  transform: 'translateY(-50%)', // 세로 중앙 정렬
                },
                shrink: false,
              }}
              value={groupName}
              onChange={handleGroupName}
              style={{
                width: '100%', // 입력창 너비 설정
              }}
            />
            <Box
              style={{
                position: 'absolute',
                bottom: '0.5rem', // 입력창 아래에 표시
                right: '0.5rem',
                fontSize: '0.75rem',
                color: '#ADB4BA',
              }}
            >
              {groupName.length}/15
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Box
            style={{
              marginLeft: '0rem',
              fontSize: '1.125rem', // 원래 폰트 크기로 복구
              fontWeight: 'bold',
              marginRight: '2rem',
              width: '10rem',
            }}
          >
            사용자 이름
          </Box>
          <Box style={{position: 'relative', width: '300px'}}>
            <TextField
              autoComplete="off"
              variant="outlined"
              label={nameText == '' ? '이름을 입력하세요.' : ''}
              InputProps={{
                style: {
                  backgroundColor: '#F7F8FA',
                  border: '1px solid #E9EBED',
                  fontSize: '1rem', // 입력 필드 글씨 크기 유지
                  borderRadius: '0.5rem', // 테두리 둥글기 조정
                  height: '2.5rem', // 필드 높이
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: '0.875rem', // 라벨 글씨 크기 유지
                  position: 'absolute', // 라벨을 절대 위치로 설정
                  top: '50%', // 중앙 정렬
                  marginLeft: '1rem',
                  transform: 'translateY(-50%)', // 세로 중앙 정렬
                },
                shrink: false,
              }}
              value={nameText}
              onChange={handleName}
              style={{
                width: '100%', // 입력창 너비 설정
              }}
            />
            <Box
              style={{
                position: 'absolute',
                bottom: '0.5rem', // 입력창 아래에 표시
                right: '0.5rem',
                fontSize: '0.75rem',
                color: '#ADB4BA',
              }}
            >
              {nameText.length}/15
            </Box>
          </Box>
        </Box>

        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Box
            style={{
              marginLeft: '0rem',
              fontSize: '1.125rem', // 원래 폰트 크기로 복구
              fontWeight: 'bold',
              marginRight: '2rem',
              width: '10rem',
            }}
          >
            키위런 ID
          </Box>
          <Box style={{position: 'relative', width: '300px'}}>
            <TextField
              autoComplete="off"
              variant="outlined"
              label={ID == '' ? '영자, 숫자 5자 이상' : ''}
              InputProps={{
                style: {
                  backgroundColor: '#F7F8FA',
                  border: '1px solid #E9EBED',
                  fontSize: '1rem', // 입력 필드 글씨 크기 유지
                  borderRadius: '0.5rem', // 테두리 둥글기 조정
                  height: '2.5rem', // 필드 높이
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: '0.875rem', // 라벨 글씨 크기 유지
                  position: 'absolute', // 라벨을 절대 위치로 설정
                  top: '50%', // 중앙 정렬
                  marginLeft: '1rem',
                  transform: 'translateY(-50%)', // 세로 중앙 정렬
                },
                shrink: false,
              }}
              value={ID}
              onChange={handleID}
              style={{
                width: '100%', // 입력창 너비 설정
              }}
            />
            <Box
              style={{
                position: 'absolute',
                bottom: '0.5rem', // 입력창 아래에 표시
                right: '0.5rem',
                fontSize: '0.75rem',
                color: '#ADB4BA',
              }}
            >
              {ID.length}/15
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Box
            style={{
              marginLeft: '0rem',
              fontSize: '1.125rem', // 원래 폰트 크기로 복구
              fontWeight: 'bold',
              marginRight: '2rem',
              width: '10rem',
            }}
          >
            비밀번호
          </Box>
          <Box style={{position: 'relative', width: '500px'}}>
            <TextField
              autoComplete="off"
              variant="outlined"
              type="text"
              label={PW == '' ? '키위런 비밀번호 (영어, 숫자 5글자 이상)' : ''}
              InputProps={{
                style: {
                  backgroundColor: '#F7F8FA',
                  border: '1px solid #E9EBED',
                  fontSize: '1rem',
                  borderRadius: '0.5rem', // 테두리 둥글기 조정
                  height: '2.5rem', // 필드 높이
                  width: '300px',
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: '0.875rem', // 라벨 글씨 크기 유지
                  position: 'absolute', // 라벨을 절대 위치로 설정
                  top: '50%', // 중앙 정렬
                  marginLeft: '1rem',
                  transform: 'translateY(-50%)', // 세로 중앙 정렬
                },
                shrink: false,
              }}
              value={PW}
              onChange={handlePW}
            />
            <Box
              style={{
                position: 'absolute',
                bottom: '1.0rem', // 입력창 아래에 표시
                right: '0.5rem',
                left: '16.5rem',
                fontSize: '0.75rem',
                color: '#ADB4BA',
              }}
            >
              {PW.length}/15
            </Box>
          </Box>
          <Button
            style={{
              marginLeft: '-10rem',
              color: isLoading ? '#FF9900' : '#4FD7C3',
              fontSize: '0.875rem',
              border: isLoading ? '1px solid #FF9900' : '1px solid #4FD7C3',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow: isLoading ? '' : '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={saveKeewiAccount}
            disabled={isLoading} // 로딩 중 버튼 비활성화
          >
            {isLoading ? '생성 중...' : '계정 생성'}
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Box
            style={{
              marginTop: '1rem',
              fontSize: '1rem',
              color: modifyResultColor,
            }}
          >
            {modifyResultMessage}
          </Box>
        </Box>
      </Box>
    );
  }

  if (props.passStatus == 'none') {
    return <Box style={{marginTop: '3rem'}}>{haveNoPass()}</Box>;
  }
  return <Box style={{marginTop: '3rem'}}>{makeKeewilID()}</Box>;
};
FragmentView.propTypes = {
  getPassInfo: PropTypes.func,
  passStatus: PropTypes.string,
  group_name: PropTypes.string,
  auth_key: PropTypes.string,
  user_id: PropTypes.string,
};
export default FragmentView;
