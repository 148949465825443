import {Box, Button} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import APIUtils from 'common/utils/APIUtils';
import TicketView1 from 'view/mypage/TicketView1';
import {removeGroup} from 'common/reducer/auth';
import PropTypes from 'prop-types';
import {saveGroup} from 'common/reducer/auth';
import GroupCodePopup from 'view/popup/GroupCodePopup';
import GroupExitPopup from 'view/popup/GroupExitPopup';

export const FragmentView = props => {
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const [groupName, setGroupName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminName, setAdminName] = useState('');
  const [groupPass, setGroupPass] = useState([]);
  const [groupCodeVisibility, setGroupCodeVisibility] = useState(false);
  const [teacherCount, setTeacherCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [groupExitPopupVisibility, SetGroupExitPopupVisibility] =
    useState(false);

  function getAdminInfo() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.AdminView(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setTeacherCount(res.data.teacher_count);
        setStudentCount(res.data.student_count);
      }
    });
  }
  function groupExitCancel() {
    SetGroupExitPopupVisibility(false);
  }
  function groupExitConfirm() {
    groupExit();
    SetGroupExitPopupVisibility(false);
  }

  function groupCodeCancel() {
    setGroupCodeVisibility(false);
  }
  function groupCodeConfirm() {
    getGroupInfo();
    setGroupCodeVisibility(false);
  }
  function groupExit() {
    const exitGroup = async () => {
      try {
        const response = await APIUtils.GroupExit(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    exitGroup().then(() => {
      dispatch(removeGroup());
      getGroupInfo();

      props.getPassInfo();
    });
  }
  function getGroupInfo() {
    const getGroupInfo = async () => {
      try {
        const response = await APIUtils.GroupInfo(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getGroupInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setGroupName(res.data.group_name);
        setAdminEmail(res.data.group_admin_email ?? '미배정');
        setAdminName(res.data.group_admin_name);

        setGroupPass(
          res.data.group_inventory.sort(function (a, b) {
            return (
              new Date(b.group_pass_log_time) - new Date(a.group_pass_log_time)
            );
          }),
        );
        dispatch(
          saveGroup({
            group_name: res.data.group_name == null ? '' : res.data.group_name,
          }),
        );
        props.getPassInfo();
      }
    });
  }
  useEffect(() => {
    getGroupInfo();
  }, []);

  function showGroupInfo() {
    if (authReducer.group_name != null && authReducer.group_name != '') {
      getAdminInfo();
      return (
        <Box>
          <GroupExitPopup
            visibility={groupExitPopupVisibility}
            group_name={authReducer.group_name}
            cancelEvent={groupExitCancel}
            confirmEvent={groupExitConfirm}
          />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: '12rem',
            }}
          >
            <Box>
              <Box style={{fontWeight: 'bold'}}>{groupName}</Box>
              <Box style={{color: '#49545C', marginTop: '0.5rem'}}>
                그룹 관리자 : {adminName} / {adminEmail}
              </Box>
            </Box>
            <Button
              style={{
                color: 'white',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                backgroundColor: '#35D2BB',
                borderRadius: '2rem',
                padding: '0.5rem 1.8rem',
                whiteSpace: 'nowrap',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                SetGroupExitPopupVisibility(true);
              }}
            >
              그룹 나오기
            </Button>
          </Box>
          <Box
            style={{
              color: '#87929D',
              marginTop: '0.5rem',
              fontSize: '0.875rem',
            }}
          >
            <Box>※ 키위티 계정은 하나의 그룹에만 속할 수 있습니다.</Box>
            <Box>
              그룹을 바꾸는 경우, 해당 그룹에서 나오기를 한 후에 새로운 그룹에
              합류합니다.
            </Box>
          </Box>
          <Box
            style={{
              marginTop: '3rem',
              fontSize: '1.125rem',
              fontWeight: 'bold',
            }}
          >
            그룹 이용권
          </Box>
          <Box style={{marginTop: '2rem'}}>
            {groupPass.map((val, idx) => {
              return (
                <TicketView1
                  key={'group_pass_view' + idx}
                  group_name={val.group_pass_name}
                  pass_student_num={val.group_pass_student_num}
                  student_count={studentCount}
                  pass_teacher_num={val.group_pass_teacher_num}
                  teacher_count={teacherCount}
                  start_time={val.group_pass_start_time}
                  end_time={val.group_pass_end_time}
                />
              );
            })}
          </Box>
        </Box>
      );
    }
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          소속 그룹이 등록되지 않았습니다.
          <Button
            style={{
              color: 'white',
              marginLeft: '10rem',
              fontSize: '0.75rem',
              fontWeight: 'bold',
              backgroundColor: '#35D2BB',
              borderRadius: '2rem',
              padding: '0.5rem 1.8rem',
              whiteSpace: 'nowrap',
            }}
            onClick={() => {
              setGroupCodeVisibility(true);
            }}
          >
            그룹 등록하기
          </Button>
        </Box>
      </Box>
    );
  }
  return (
    <Box style={{marginTop: '3rem'}}>
      <GroupCodePopup
        visibility={groupCodeVisibility}
        confirmEvent={groupCodeConfirm}
        cancelEvent={groupCodeCancel}
      />
      {showGroupInfo()}
    </Box>
  );
};
FragmentView.propTypes = {
  getPassInfo: PropTypes.func,
};
export default FragmentView;
