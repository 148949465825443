import React from 'react';
import {Box, Button} from '@mui/material';

import logoTBAI from 'asset/image/logo_tbai.png';
import {footerBox1} from 'view/login/Styles';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

const FooterView = () => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const isSoluny =
    authReducer.group_name != null &&
    authReducer.group_name.startsWith('대교 솔루니');

  return (
    <Box style={footerBox1}>
      <Box>
        <Box>
          <Box style={{display: 'flex'}}>
            <img src={logoTBAI} alt={'logoT'} style={{height: '60px'}} />{' '}
            <Button
              style={{
                color: '#49545C',
                fontSize: '0.8rem',
                fontWeight: '700',
              }}
              onClick={() => {
                navigate('/term');
              }}
            >
              이용 약관
            </Button>
            <Button
              style={{
                color: '#49545C',
                fontSize: '0.8rem',
                fontWeight: '700',
              }}
              onClick={() => {
                navigate('/personal');
              }}
            >
              개인정보 처리방침
            </Button>
          </Box>
          <Box
            ml={2}
            style={{
              fontSize: '0.8rem',
              fontWeight: '500',
              color: '#68737D',
            }}
          >
            <Box style={{marginTop: '1.5rem'}}>
              상호: TwoBlockAi&nbsp;&nbsp;&nbsp;&nbsp;대표자: 조영환
            </Box>
            <Box style={{marginTop: '0.5rem'}}>
              주소: 서울시 서초구 남부순환로 350길 54 V-TOWER
              1층&nbsp;&nbsp;&nbsp;&nbsp;사업자등록번호: 316-88-01580
            </Box>
            <Box style={{marginTop: '0.5rem'}}>
              통신판매업신고: 2020-서울서초-4283&nbsp;&nbsp;&nbsp;&nbsp;Email:
              support@twoblockai.com
            </Box>
            <Box style={{marginTop: '0.5rem', color: '#A8b1b7'}}>
              Copyright (C) 2023 TwoBlockAi All Rights Reserved.
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          alignItems: 'start',
          marginRight: '7rem',
        }}
      >
        <Box
          style={{
            color: '#2F3941',
            fontSize: 'calc(0.5rem + 1vw)',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
        >
          고객 센터
        </Box>
        <Box
          style={{
            display: isSoluny ? 'none' : '',
            color: '#35D2BB',
            fontSize: '1.375rem',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            marginTop: '0.8rem',
          }}
        >
          02-6677-0300
        </Box>
        <Box
          style={{
            display: isSoluny ? 'none' : '',
            color: '#35D2BB',
            fontSize: '1.375rem',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            marginTop: '0.8rem',
          }}
        >
          02-6677-0400
        </Box>
        <Box
          style={{
            display: isSoluny ? '' : 'none',
            color: '#35D2BB',
            fontSize: '1.375rem',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            marginTop: '0.8rem',
          }}
        >
          02-6677-0222
        </Box>
        <Box
          style={{
            color: '#68737D',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
            marginTop: '0.5rem',
          }}
        >
          평일 10:00 - 17:00
        </Box>
        <Box
          style={{
            color: '#68737D',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
            marginTop: '0.3rem',
          }}
        >
          주말, 공휴일 휴무
        </Box>
      </Box>
    </Box>
  );
};
export default FooterView;
