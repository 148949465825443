import {
  Box,
  Button,
  Checkbox,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import {useSelector} from 'react-redux';
import React, {useState, useEffect} from 'react';
import Pagination from 'view/mypage/Pagination';
import StudentTableItem from 'view/mypage/StudentTableItem';
import APIUtils from 'common/utils/APIUtils';
import StudentRemoveDeniedPopup from 'view/popup/StudentRemoveDeniedPopup';
import StudentRemovePopup from 'view/popup/StudentRemovePopup';
import StudentModifyMemoPopup from 'view/popup/StudentModifyMemoPopup';
import StudentPasswordPopup from 'view/popup/StudentPasswordPopup';
import ApiLoading from 'view/common/ApiLoading2';

const StudentTable = () => {
  const authReducer = useSelector(state => state.authReducer);

  const [loading, setLoading] = useState(true);
  const [classList, setClassList] = useState([]);
  const [classSelect, setClassSelect] = useState(0);
  const [originalStudentTable, setOriginalStudentTable] = useState([]);
  const [filteredStudentTable, setFilteredStudentTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkList, setCheckList] = useState([]);
  const [taskViewList, setTaskViewList] = useState([]);
  const numPerPage = 15;
  const totalPage = Math.floor(
    (filteredStudentTable.length - 1) / numPerPage + 1,
  );
  const [searchText, setSearchText] = useState('');
  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
  };
  //student password popup
  const [studentPasswordVisibility, setStudentPasswordVisibility] =
    useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(0);
  const [selectedStudentEmail, setSelectedStudentEmail] = useState('');
  function confirmStudentPassword() {
    resetPassword(selectedStudentId);
    setStudentPasswordVisibility(false);
  }
  function cancelStudentPassword() {
    setStudentPasswordVisibility(false);
  }

  //student remove popup
  const [studentRemoveVisibility, setStudentRemoveVisibility] = useState(false);
  function confirmStudentRemove() {
    removeStudent();
  }
  function cancelStudentRemove() {
    setStudentRemoveVisibility(false);
  }

  // 학생당 제출한 과제 리스트 출력 목록
  function updateTaskViewList(action, student_id) {
    if (action === 'add') {
      // 추가: student_id를 리스트에 추가
      if (!taskViewList.includes(student_id)) {
        setTaskViewList([...taskViewList, student_id]);
      }
    } else if (action === 'remove') {
      // 삭제: student_id를 리스트에서 제거
      setTaskViewList(taskViewList.filter(id => id !== student_id));
    }
    console.log(
      '학생당 제출한 과제 리스트 updateTaskViewList : ' +
        action +
        ', ' +
        student_id,
    );
    console.log('학생당 제출한 과제 리스트 StudentTable : ' + taskViewList);
  }

  //student remove popup
  const [studentRemoveDeniedVisibility, setStudentRemoveDeniedVisibility] =
    useState(false);
  const [deniedStudentList, setDeniedStudentList] = useState([]);
  function cancelStudentRemoveDenied() {
    setStudentRemoveDeniedVisibility(false);
  }
  //student modify memo popup
  const [studentModifyMemoVisibility, setStudentModifyMemoVisibility] =
    useState(false);
  const [modifyingMemo, setModifyingMemo] = useState(undefined);
  function confirmModifyMemo(newMemo) {
    const modifyMemoApi = async (student_id_input, memo_input) => {
      try {
        const response = await APIUtils.ClassStudentMemo(
          authReducer.user_id,
          student_id_input,
          memo_input,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    modifyMemoApi(modifyingMemo.student_id, newMemo).then(res => {
      if (res.data.ret_code == 1000) {
        getOriginalStudentList();
      }
      setStudentModifyMemoVisibility(false);
    });
  }
  function cancelModifyMemo() {
    setStudentModifyMemoVisibility(false);
  }

  const handleSelectAdd = event => {
    const addStudentClass = async (ids, students) => {
      try {
        const response = await APIUtils.ClassAssignStudents(
          authReducer.user_id,
          ids,
          students,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const selectedClass = classList[event.target.value];
    const selectStudents = checkList
      .filter(
        i =>
          filteredStudentTable[i + (currentPage - 1) * numPerPage]
            .student_class_list.length < 3 &&
          !filteredStudentTable[
            i + (currentPage - 1) * numPerPage
          ].student_class_list.includes(selectedClass.class_name),
      )
      .map(
        i =>
          filteredStudentTable[i + (currentPage - 1) * numPerPage].student_id,
      );
    if (selectStudents.length == 0) {
      return;
    }
    addStudentClass(selectedClass.class_id, selectStudents).then(res => {
      if (res.data.ret_code == 1000) {
        getOriginalStudentList();
      }
    });
  };
  const handleSelectRemove = event => {
    const removeStudentClass = async (ids, students) => {
      try {
        const response = await APIUtils.ClassExceptStudents(
          authReducer.user_id,
          ids,
          students,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const selectedClass = classList[event.target.value];
    const selectStudents = checkList
      .filter(i =>
        filteredStudentTable[
          i + (currentPage - 1) * numPerPage
        ].student_class_list.includes(selectedClass.class_name),
      )
      .map(
        i =>
          filteredStudentTable[i + (currentPage - 1) * numPerPage].student_id,
      );
    if (selectStudents.length == 0) {
      return;
    }
    removeStudentClass(selectedClass.class_id, selectStudents).then(res => {
      if (res.data.ret_code == 1000) {
        getOriginalStudentList();
      }
    });
  };
  const handleSelectFilter = event => {
    setClassSelect(event.target.value);
  };
  function clickEvent(page) {
    setCheckList([]);
    setCurrentPage(page);
  }
  function handleCheck(idx) {
    const temp = [...checkList];

    const removeIdx = temp.indexOf(idx);
    if (removeIdx == -1) {
      temp.push(idx);
    } else {
      temp.splice(removeIdx, 1);
    }
    setCheckList(temp);
  }
  function handleCheckAll() {
    if (
      filteredStudentTable.filter((value, index) => {
        if (Math.floor(index / numPerPage) + 1 == currentPage) {
          return true;
        }
        return false;
      }).length == checkList.length
    ) {
      setCheckList([]);
    } else {
      const checkLength = filteredStudentTable.filter((value, index) => {
        if (Math.floor(index / numPerPage) + 1 == currentPage) {
          return true;
        }
        return false;
      }).length;
      setCheckList([...Array(checkLength).keys()]);
    }
  }

  function studentTableFilter(searchValue, classFilter) {
    if (originalStudentTable == undefined || originalStudentTable.length == 0) {
      setFilteredStudentTable([]);
      return;
    }
    let filtered = [...originalStudentTable];
    if (searchValue != undefined && searchValue.trim() != '') {
      filtered = filtered.filter(item => {
        return (
          item.student_name != undefined &&
          item.student_name.includes(searchValue)
        );
      });
    }
    if (classFilter != 0) {
      const className = classList[classFilter - 1].class_name;
      filtered = filtered.filter(item => {
        return (
          item.student_class_list != undefined &&
          item.student_class_list.includes(className)
        );
      });
    }
    filtered.sort((a, b) => {
      if (a.student_class_list.length != b.student_class_list.length) {
        return a.student_class_list.length - b.student_class_list.length;
      }
      if (a.student_name > b.student_name) {
        return 1;
      }
      if (a.student_name < b.student_name) {
        return -1;
      }
      return 0;
    });
    setFilteredStudentTable(filtered);
  }

  function removeStudent() {
    const studentList = checkList.map(
      i => filteredStudentTable[i + (currentPage - 1) * numPerPage].student_id,
    );
    const removeStudentApi = async removeStudents => {
      try {
        const response = await APIUtils.ClassStudentDelete(
          authReducer.user_id,
          removeStudents,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    removeStudentApi(studentList).then(res => {
      setStudentRemoveVisibility(false);
      if (res.data.ret_code == 1000) {
        getOriginalStudentList();
      } else if (res.data.ret_code == 2601) {
        setDeniedStudentList(res.data.task_include_student_list);
        setStudentRemoveDeniedVisibility(true);
      }
    });
  }
  function getOriginalStudentList() {
    const getStudents = async () => {
      try {
        const response = await APIUtils.ClassStudent(authReducer.user_id);
        setOriginalStudentTable(response.data.student_list);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getStudents();
  }
  function getClassList() {
    const getClasses = async () => {
      try {
        const response = await APIUtils.ClassView(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getClasses().then(res => {
      if (res.data.ret_code == 1000) {
        setClassList(res.data.class_list);
      }
    });
  }
  function showPWPopup(student_id, student_email) {
    setSelectedStudentId(student_id);
    setSelectedStudentEmail(student_email);
    setStudentPasswordVisibility(true);
  }
  function resetPassword(student_id) {
    const resetStudentPassword = async () => {
      try {
        const response = await APIUtils.ClassStudentPasswordReset(
          authReducer.user_id,
          student_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    resetStudentPassword().then(res => {
      // console.log(res);
    });
  }
  useEffect(() => {
    setCheckList([]);
  }, [currentPage, originalStudentTable, searchText, classSelect]);
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredStudentTable]);
  useEffect(() => {
    getOriginalStudentList();
    getClassList();
  }, []);
  useEffect(() => {
    studentTableFilter(searchText, classSelect);
  }, [originalStudentTable, searchText, classSelect]);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box style={{width: '100%', minWidth: '40rem'}}>
      <StudentRemovePopup
        visibility={studentRemoveVisibility}
        cancelEvent={cancelStudentRemove}
        confirmEvent={confirmStudentRemove}
      />
      <StudentRemoveDeniedPopup
        visibility={studentRemoveDeniedVisibility}
        cancelEvent={cancelStudentRemoveDenied}
        deniedStudentList={deniedStudentList}
      />
      <StudentModifyMemoPopup
        visibility={studentModifyMemoVisibility}
        cancelEvent={cancelModifyMemo}
        confirmEvent={confirmModifyMemo}
        modifyingMemo={modifyingMemo}
      />
      <StudentPasswordPopup
        visibility={studentPasswordVisibility}
        studentEmail={selectedStudentEmail}
        cancelEvent={cancelStudentPassword}
        confirmEvent={confirmStudentPassword}
      />
      <Box
        style={{
          display: 'flex',
          marginTop: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Button
            style={{
              height: '2.5rem',
              borderRadius: '0.9rem',
              color: '#FF9900',
              whiteSpace: 'nowrap',
              padding: '0rem 1rem',
              fontSize: '0.875rem',
              border: '1px solid #FF9900',
            }}
            onClick={() => {
              if (checkList.length != 0) {
                setStudentRemoveVisibility(true);
              }
            }}
          >
            선택 학생 삭제
          </Button>
          <Select
            disableUnderline
            onChange={handleSelectAdd}
            value={''}
            variant="standard"
            renderValue={() => 'class 배정'}
            displayEmpty
            style={{
              border: '1px solid #35D2BB',
              height: '2.5rem',
              borderRadius: '0.9rem',
              color: '#35D2BB',
              fontSize: '0.875rem',
              whiteSpace: 'nowrap',
              padding: '0rem 1rem',
            }}
          >
            {classList.map((value, index) => (
              <MenuItem key={index} value={index}>
                {value.class_name}
              </MenuItem>
            ))}
          </Select>
          <Select
            disableUnderline
            onChange={handleSelectRemove}
            value={''}
            variant="standard"
            renderValue={() => 'class 해제'}
            displayEmpty
            style={{
              border: '1px solid #898989',
              height: '2.5rem',
              borderRadius: '0.9rem',
              color: 'black',
              fontSize: '0.875rem',
              whiteSpace: 'nowrap',
              padding: '0rem 1rem',
            }}
          >
            {classList.map((value, index) => (
              <MenuItem key={index} value={index}>
                {value.class_name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <Select
            disableUnderline
            onChange={handleSelectFilter}
            value={classSelect}
            variant="standard"
            renderValue={val => {
              if (val == 0) {
                return 'class 검색';
              }
              return classList[val - 1]?.class_name || 'class 검색';
            }}
            displayEmpty
            style={{
              border: '1px solid #E9EBED',
              height: '2.5rem',
              borderRadius: '0.9rem',
              color: '#2F3941',
              fontSize: '0.875rem',
              whiteSpace: 'nowrap',
              padding: '0rem 1rem',
              marginRight: '1rem',
            }}
          >
            {[{class_name: '전체'}, ...classList].map((value, index) => (
              <MenuItem key={index} value={index}>
                {value.class_name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            autoComplete="off"
            size="small"
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid black',
                },
                '&:hover fieldset': {
                  border: '1px solid black',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid black',
                },
              },
            }}
            label={searchText == '' ? '🔍 검색(학생 이름)' : ''}
            InputLabelProps={{
              style: {
                color: 'rgba(108, 108, 108, 0.721)',
                fontSize: 'calc(0.3rem + 0.5vw)',
              },
              shrink: false,
            }}
            InputProps={{style: {fontSize: 'calc(0.3rem + 0.5vw)'}}}
            value={searchText}
            onChange={handleSearchText}
          ></TextField>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: '#F7F8FA',
          borderRadius: '0.625rem',
          marginTop: '1.2rem',
        }}
      >
        <Checkbox
          checked={
            filteredStudentTable.filter((value, index) => {
              if (Math.floor(index / 15) + 1 == currentPage) {
                return true;
              }
              return false;
            }).length == checkList.length && checkList.length != 0
          }
          onChange={() => {
            handleCheckAll();
          }}
          style={{color: '#35D2BB'}}
        />
      </Box>
      <Box>
        {filteredStudentTable
          .filter((value, index) => {
            if (Math.floor(index / numPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <StudentTableItem
                key={'studentList' + index}
                student_id={value.student_id}
                student_class_list={value.student_class_list}
                student_email={value.student_email}
                student_name={value.student_name}
                student_memo={value.student_memo}
                student_access_path={value.access_path}
                student_status={value.status}
                task_view_list={taskViewList}
                teacher_id={authReducer.user_id}
                updateTaskViewList={updateTaskViewList}
                handleCheck={handleCheck}
                index={index}
                checked={checkList.includes(index)}
                startModifyEvent={() => {
                  setModifyingMemo(value);
                  setStudentModifyMemoVisibility(true);
                }}
                // resetPWEvent={resetPassword}
                resetPWEvent={showPWPopup}
              />
            );
          })}
      </Box>
      <Box style={{marginTop: '3rem', display: 'flex', gap: '0.5rem'}}>
        <Button
          style={{
            height: '2.5rem',
            borderRadius: '0.9rem',
            color: '#FF9900',
            whiteSpace: 'nowrap',
            padding: '0rem 1rem',
            fontSize: '0.875rem',
            border: '1px solid #FF9900',
          }}
          onClick={() => {
            if (checkList.length != 0) {
              setStudentRemoveVisibility(true);
            }
          }}
        >
          선택 학생 삭제
        </Button>
        <Select
          disableUnderline
          onChange={handleSelectAdd}
          value={''}
          variant="standard"
          renderValue={() => 'class 배정'}
          displayEmpty
          style={{
            border: '1px solid #35D2BB',
            height: '2.5rem',
            borderRadius: '0.9rem',
            color: '#35D2BB',
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            padding: '0rem 1rem',
          }}
        >
          {classList.map((value, index) => (
            <MenuItem key={index} value={index}>
              {value.class_name}
            </MenuItem>
          ))}
        </Select>
        <Select
          disableUnderline
          onChange={handleSelectRemove}
          value={''}
          variant="standard"
          renderValue={() => 'class 해제'}
          displayEmpty
          style={{
            border: '1px solid #898989',
            height: '2.5rem',
            borderRadius: '0.9rem',
            color: 'black',
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            padding: '0rem 1rem',
          }}
        >
          {classList.map((value, index) => (
            <MenuItem key={index} value={index}>
              {value.class_name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          paddingBottom: '2rem',
          fontWeight: 'bold',
          fontSize: 'calc(0.8rem + 0.5vw)',
        }}
      >
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          clickEvent={clickEvent}
        />
      </Box>
    </Box>
  );
};
export default StudentTable;
