import React, {useEffect, useState} from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import {NumberUtils} from 'common/utils/NumberUtils';
import {StringUtils} from 'common/utils/StringUtils';
import {
  MarginBoxComponent,
  WhiteBox,
  TitleComponent2,
  SingleLineTitle,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import CommentItem from 'view/comment2/CommentItem';
import HeaderView from 'view/common/Header';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import FooterView from 'view/common/Footer';
import RadarChart from 'view/common/RadarChart';
import KeewiCloud from 'view/common/KeewiCloud';
import ArrowGreen1 from 'asset/imageV2/icon_green_arrow1.svg';
import ArrowGrey1 from 'asset/imageV2/icon_grey_arrow1.svg';

import {
  categoryTextBox,
  overallBox,
  overallLeftBox,
  overallTextBox,
} from 'view/result/Styles';

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [commentParagraph, setCommentParagraph] = useState([]);

  const [submitList, setSubmitList] = useState([]);
  const [submitListLength, setSubmitListLength] = useState(0);
  const [prevStudentID, setPrevStudentID] = useState(-1);
  const [nextStudentID, setNextStudentID] = useState(-1);
  const [prevStudentName, setPrevStudentName] = useState('');
  const [nextStudentName, setNextStudentName] = useState('');
  const [thisStudentIndex, setThisStudentIndex] = useState(-1);

  const [loading, setLoading] = useState(true);

  const [taskID, setTaskID] = useState(0);
  const [studentID, setStudentID] = useState(0);
  const [titleText, setTitleText] = useState('');
  const [nameText, setNameText] = useState('');
  const [labelText, setLabelText] = useState('');
  const [analyText, setAnalyText] = useState('');

  const [tScore, setTScore] = useState(0);
  const [keewiScore, setKeewiScore] = useState({
    겹치는단어비율: 0,
    단어의평균길이: 0,
    문단별문장수: 0,
    문장별단어수: 0,
    분석오류문장수: 0,
    전체글자수: 0,
    전체문장수: 0,
    전체문단수: 0,
    흐름이자연스러운정도: 0,
    긴문장의수: 0,
    독자나이: 0,
  });
  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: [],
  });

  const [wordCloudData, setWordCloudData] = useState([
    {text: '키위', value: 1},
    {text: '글쓰기', value: 2},
    {text: '평가', value: 3},
    {text: '엔진', value: 4},
    {text: '피드백', value: 5},
  ]);

  const [overallComment, setOverallComment] = useState('');
  const [isGPTText, setisGPTText] = useState(false);

  function showGPTValidation() {
    if (isGPTText) {
      return (
        <Box
          style={{
            backgroundColor: '#f5d7da',
            color: '#bb0000',
            fontSize: '1rem',
            padding: '0.5rem',
          }}
        >
          ⚠ GPT로 생성한 글일 가능성이 높습니다.
        </Box>
      );
    }
  }
  function getPrevAndNextStudent(submitList = [], this_id) {
    if (!Array.isArray(submitList) || submitList.length === 0) {
      // submitList가 배열이 아니거나 비어있을 경우 null 반환
      return {prev: null, next: null};
    }
    // 현재 학생 ID의 인덱스 찾기
    const currentIndex = submitList.findIndex(
      student => student.student_id === this_id,
    );
    setThisStudentIndex(currentIndex);
    setSubmitListLength(submitList.length);
    if (currentIndex === -1) {
      // 현재 학생 ID가 리스트에 없는 경우
      return {prev: null, next: null};
    }

    // 이전 학생
    const prevStudent = currentIndex > 0 ? submitList[currentIndex - 1] : null;

    // 다음 학생
    const nextStudent =
      currentIndex < submitList.length - 1
        ? submitList[currentIndex + 1]
        : null;

    // 이전과 다음 학생의 ID와 이름 반환
    return {
      prev: prevStudent
        ? {
            student_id: prevStudent.student_id,
            student_name: prevStudent.student_name,
          }
        : null,
      next: nextStudent
        ? {
            student_id: nextStudent.student_id,
            student_name: nextStudent.student_name,
          }
        : null,
    };
  }
  function setAnalysisInfoFromKeewiApiLoad(res) {
    console.log(res);
    setTitleText(res.data.eval_result.title);
    setNameText(res.data.eval_result.student_name);
    setTScore(NumberUtils.getAnalysisNumber(res.data.eval_result.score));
    const keewiScore = {
      겹치는단어비율: res.data.eval_result.겹치는단어비율,
      단어의평균길이: res.data.eval_result.단어의평균길이,
      문단별문장수: res.data.eval_result.문단별문장수,
      문장별단어수: res.data.eval_result.문장별단어수,
      분석오류문장수: res.data.eval_result.분석오류문장수,
      전체글자수: res.data.eval_result.전체글자수,
      전체문장수: res.data.eval_result.전체문장수,
      전체문단수: res.data.eval_result.전체문단수,
      흐름이자연스러운정도: res.data.eval_result.흐름이자연스러운정도,
      긴문장의수: res.data.eval_result.긴문장의수 ?? 0,
      독자나이: res.data.eval_result.독자나이 ?? 10,
    };
    setKeewiScore(keewiScore);

    setCommentParagraph(
      AnalysisText.createCommentFromSaved(
        res.data.eval_result.originalText,
        res.data.eval_result.commentList,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));

    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );
    setOverallComment(res.data.eval_result.comment);
    setisGPTText(res.data.eval_result.is_gpt_text);

    let datasets = [];
    datasets = [
      {
        data: [
          AnalysisText.getNumericScore(res.data.eval_result.trait_1_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_2_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_3_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_4_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_5_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_6_score),
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
    const wordCloudData = res.data.eval_result.word_cloud;
    setWordCloudData(wordCloudData);
  }
  function createWholeStatisticsText() {
    let flowLevel = '어려움';
    if (keewiScore.흐름이자연스러운정도 >= 71) flowLevel = '쉬움';
    else if (keewiScore.흐름이자연스러운정도 >= 63) flowLevel = '보통';
    return (
      <Box style={{width: '90%', marginBottom: '3rem'}}>
        <Box>
          {createStatisticsText('전체 글자 수', keewiScore.전체글자수, '자')}
        </Box>
        <Box>
          {createStatisticsText('전체 문단 수', keewiScore.전체문단수, '개')}
        </Box>
        <Box>
          {createStatisticsText('전체 문장 수', keewiScore.전체문장수, '개')}
        </Box>
        <Box>
          {createStatisticsText(
            '문단별 문장 수',
            keewiScore.문단별문장수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText(
            '긴 문장(50자)의 수',
            keewiScore.긴문장의수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText('예상 독자 나이', keewiScore.독자나이, '세')}
        </Box>
        <Box>{createStatisticsText('읽기 쉬운 정도', flowLevel, '')}</Box>
      </Box>
    );
  }
  function createStatisticsText(title, score, rest) {
    return (
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '0.4rem',
          fontSize: '1rem',
          fontWeight: '500',
        }}
      >
        <Box>{title}</Box>
        <Box>
          <span style={{color: '#f67f10'}}>{score}</span> {rest}
        </Box>
      </Box>
    );
  }
  function createOverallComment() {
    return (
      <Box style={overallBox}>
        <Box style={overallLeftBox}>총평</Box>
        <Box
          style={{
            backgroundColor: '#D8DCDE',
            height: '2px',
            width: '100%',
            margin: '1rem 0rem',
          }}
        ></Box>
        <Box style={overallTextBox}>{overallComment}</Box>
      </Box>
    );
  }

  function clickViewPopup(taskID, studentID, submitList) {
    navigate('/result2', {
      state: {
        task_id: taskID,
        student_id: studentID,
        submitList: submitList,
      },
    });
    window.location.reload();
  }
  function viewComplete() {
    navigate('/mypage', {
      replace: true,
      state: {
        fragmentNum: 7,
        selectedTaskID: taskID,
      },
    });
  }
  useEffect(() => {
    let inputTaskID = 0;
    let inputStudentID = 0;
    let inputFromPage = '';
    let submitList = [];
    if (
      location.state == null &&
      window.localStorage.getItem('task_id') == null
    ) {
      navigate('/', {replace: true});
      return;
    } else if (location.state == null) {
      inputTaskID = Number(window.localStorage.getItem('task_id'));
      inputStudentID = Number(window.localStorage.getItem('student_id'));
      submitList = window.localStorage.getItem('submit_list', submitList);
      inputFromPage = window.localStorage.getItem('from');
    } else {
      inputTaskID = location.state.task_id;
      inputStudentID = location.state.student_id;
      inputFromPage = location.state.from;
      submitList = location.state.submitList;
      window.localStorage.setItem('task_id', location.state.task_id);
      window.localStorage.setItem('student_id', location.state.student_id);
      window.localStorage.setItem('from', location.state.from);
      window.localStorage.setItem('submit_list', location.state.submitList);
    }
    setTaskID(inputTaskID);
    setStudentID(inputStudentID);
    setSubmitList(submitList);
    const nearStudents = getPrevAndNextStudent(submitList, inputStudentID);
    setPrevStudentID(nearStudents.prev ? nearStudents.prev.student_id : -1);
    setNextStudentID(nearStudents.next ? nearStudents.next.student_id : -1);
    setPrevStudentName(
      nearStudents.prev ? nearStudents.prev.student_name : null,
    );
    setNextStudentName(
      nearStudents.next ? nearStudents.next.student_name : null,
    );

    const setAnalasisResult = async (task_id, student_id) => {
      try {
        const response = await APIUtils.TaskEvalEdit(
          authReducer.user_id,
          task_id,
          student_id,
        );
        setAnalysisInfoFromKeewiApiLoad(response);
      } catch (err) {
        console.log(err);
      }
    };
    setAnalasisResult(inputTaskID, inputStudentID).then(r => {
      setLoading(false);
    });
    const backspace = e => {
      if (
        e.keyIdentifier == 'U+0008' ||
        e.keyIdentifier == 'Backspace' ||
        e.keyCode == 8
      ) {
        if (e.target == document.body) {
          e.preventDefault();
          if (inputFromPage == 'finished') {
            navigate('/mypage', {
              replace: true,
              state: {
                fragmentNum: 6,
                selectedTaskID: inputTaskID,
              },
            });
          }
          return false;
        }
      }
    };
    window.addEventListener('keydown', backspace, true);
    const preventGoBack = () => {
      history.pushState(null, '', location.href);
      if (inputFromPage == 'finished') {
        navigate('/mypage', {
          replace: true,
          state: {
            fragmentNum: 6,
            selectedTaskID: inputTaskID,
          },
        });
      } else {
        navigate('/mypage', {
          replace: true,
          state: {
            fragmentNum: 7,
            selectedTaskID: inputTaskID,
          },
        });
      }
    };
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', preventGoBack);
    return () => {
      window.removeEventListener('keydown', backspace, true);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeaderView />
      <TitleComponent2
        text1="제출 결과"
        text2="키위 AI 분석을 토대로 학생이 제출한 결과입니다."
      />
      {showGPTValidation()}
      <MarginBoxComponent>
        <>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '2rem',
            }}
          >
            {/* 오른쪽: 앞뒤로 이동 버튼 */}
            <Box
              style={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {/* 이전 버튼 */}
              <Button
                style={{
                  whiteSpace: 'nowrap',
                  color: prevStudentID != -1 ? 'black' : '#898989',
                  fontSize: '0.875rem',
                  marginLeft: '1rem',
                  minWidth: '6rem',
                  maxWidth: '10rem',
                  borderRadius: '1.625rem',
                }}
                onClick={() =>
                  prevStudentID != -1 &&
                  clickViewPopup(taskID, prevStudentID, submitList)
                }
                disabled={prevStudentID == -1}
              >
                <img
                  style={{
                    marginRight: '0.5rem',
                    rotate: '180deg',
                  }}
                  src={prevStudentID != -1 ? ArrowGreen1 : ArrowGrey1}
                ></img>
                {prevStudentID != -1 ? ` ${prevStudentName}` : ' 이전 없음'}
              </Button>

              {/* 저장하기 버튼 */}
              <Box
                style={{
                  color: 'black',
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  padding: '0.7rem 2rem',
                  marginLeft: '4rem',
                  marginRight: '4rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {' '}
                {nameText}
                {' ('}
                {thisStudentIndex + 1}/{submitListLength})
              </Box>

              {/* 다음 버튼 */}
              <Button
                style={{
                  whiteSpace: 'nowrap',
                  color: nextStudentID != -1 ? 'black' : '#898989',
                  fontSize: '0.875rem',
                  minWidth: '6rem',
                  maxWidth: '10rem',
                  borderRadius: '1.625rem',
                }}
                onClick={() =>
                  nextStudentID != -1 &&
                  clickViewPopup(taskID, nextStudentID, submitList)
                }
                disabled={nextStudentID == -1}
              >
                {nextStudentID != -1 ? `${nextStudentName} ` : '다음 없음 '}
                <img
                  style={{
                    marginLeft: '0.5rem',
                  }}
                  src={nextStudentID != -1 ? ArrowGreen1 : ArrowGrey1}
                ></img>
              </Button>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end', // 오른쪽 끝으로 정렬
              width: '100%', // 부모 요소가 화면 전체 너비를 차지
            }}
          >
            <Button
              style={{
                color: '#279EFF',
                fontSize: '1.125rem',
                fontWeight: 'bold',
                borderRadius: '1.625rem',
                whiteSpace: 'nowrap',
                justifyContent: 'flex-end',
              }}
              onClick={() => viewComplete(true)}
            >
              ◀ 목록으로 돌아가기
            </Button>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              color: '#68737d',
              fontWeight: '700',
              fontSize: '1rem',
              marginTop: '1rem',
            }}
          >
            <Box>학생 이름 | {nameText}</Box>
            <Box style={{flex: 1}}></Box>
          </Box>
          <Box
            style={{
              borderBottom: '1px solid #7d7d7d',
              marginTop: '2rem',
              marginBottom: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <SingleLineTitle title={titleText} tScore={tScore} />
          </Box>

          <Box style={{display: 'flex', marginBottom: '2rem'}}>
            {createOverallComment()}
          </Box>
          <Grid container spacing={'1rem'}>
            <Grid
              item
              xs={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                내용 분석
              </Box>
              <WhiteBox>
                <>
                  {labelText}
                  {analyText}
                </>
              </WhiteBox>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                글 통계 자료
              </Box>
              <WhiteBox>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: '100%',
                  }}
                >
                  {createWholeStatisticsText()}
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                      marginBottom: '2rem', // 간격 추가
                    }}
                  >
                    <RadarChart radarData={radarData} />
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                      alignItems: 'center',
                      justifyContent: 'center', // 중앙 정렬
                    }}
                  >
                    <KeewiCloud data={wordCloudData} />
                  </Box>
                </Box>
              </WhiteBox>
            </Grid>
          </Grid>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              marginTop: '3rem',
            }}
          >
            {commentParagraph.map((paragraph, paragraphIndex) => {
              if (paragraph.length <= 2) {
                return;
              }
              if (paragraph.length == 3 && paragraphIndex == 1) {
                return;
              }
              return paragraph.map((value, idx) => {
                return (
                  <CommentItem
                    key={'commentbox' + paragraphIndex + '_' + idx}
                    paragraphIndex={paragraphIndex}
                    idx={idx}
                    commentID={value.commentID}
                    type={value.type}
                    A={value.A}
                    B={value.B}
                    C={value.C}
                    subtype={value.subtype}
                    text={value.text}
                  />
                );
              });
            })}
          </Box>
        </>
      </MarginBoxComponent>
      <FooterView />;
    </Box>
  );
};
export default MainPage;
