import React from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';

const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
            marginTop: '1.5rem',
            textAlign: 'center',
            color: '#35D2BB',
          }}
        >
          <font color="#00C500"> {props.group_name} </font>그룹에서 나옵니다.
        </Box>
        <Box style={{color: '#68737D', marginTop: '3rem', textAlign: 'center'}}>
          선생님이 그룹코드를 입력하면 즉시 다시 그룹에 합류할 수 있습니다.
          선생님 그룹에서 나오기를 하여도 연결된 학생이 모두 유지되며 추후에
          다른 그룹에 합류하여도 연결된 학생은 모두 그대로 유지됩니다. 하지만,
          보유한 이용권이 연결된 학생수 보다 여유가 있어야 합류할 수 있습니다.
        </Box>

        <Box style={{marginTop: '3rem', display: 'flex'}}>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: ' #35D2BB',
              backgroundColor: 'white',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
              border: '1px solid #35D2BB',
            }}
            onClick={() => props.cancelEvent()}
          >
            취소
          </Button>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: '#35D2BB',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => {
              props.confirmEvent();
            }}
          >
            그룹 나오기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  group_name: PropTypes.string,
  cancelEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
export default ItemView;
