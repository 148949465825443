import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TaskEvalView = props => {
  function withPDFButton() {
    if (
      props.submitTask.pdf_url != '' &&
      props.submitTask.pdf_url != 'making'
    ) {
      return (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Button
            style={{
              color: '#279EFF',
              fontSize: '0.875rem',
              borderRadius: '1.925rem',
              marginTop: '0.5rem',
              padding: '0.5rem 1rem',
              backgroundColor: 'white',
              border: '1px solid #279EFF',
              width: '8rem',
            }}
            onClick={() => {
              window.open(props.submitTask.pdf_url);
            }}
          >
            {props.submitTask.pdf_url == '' ? '' : '공유한 PDF'}
          </Button>
        </Box>
      );
    }
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.75rem',
        }}
      >
        <Box
          style={{
            color: '#636C73',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            justifyContent: 'start',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <span
            style={{
              marginRight: '0.5rem',
              color: StringUtils.compareDate(
                props.submitTask.submit_time,
                props.endTime,
              )
                ? '#636C73'
                : 'red',
            }}
          >
            {StringUtils.getDateTimeKr(props.submitTask.submit_time)}
          </span>
          <span
            style={{
              marginRight: '0.5rem',
              color: 'black',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              alignItems: 'center', // 수직 중앙 정렬
              fontSize: '1rem',
            }}
          >
            {props.submitTask.student_name}
          </span>
          {props.submitTask.class_name_list
            .sort((a, b) => {
              if (a > b) return 1;
              if (a < b) return -1;
              return 0;
            })
            .map((value, idx) => {
              let classBreak = '';
              if (idx != props.submitTask.class_name_list.length - 1) {
                classBreak = <Box style={{marginLeft: '0.5rem'}}> </Box>;
              }
              return (
                <Box
                  key={'class' + idx}
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  <Box>{value}</Box>
                  {classBreak}
                </Box>
              );
            })}
          <Box
            style={{
              display: props.submitTask.edit_time ? '' : 'none',
              marginLeft: '0.5rem',
              textAlign: 'center',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              color: '#FF9900',
            }}
          >
            {' '}
            평가 저장:{' '}
            {StringUtils.getDateTimeString(props.submitTask.edit_time)}
          </Box>
        </Box>
        <Box
          style={{
            fontSize: '1.125rem',
            fontWeight: 'bold',
          }}
        >
          {props.submitTask.title + ' (' + props.submitTask.score + '점, '}
          {props.submitTask.eval_try + '번 수정)'}
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row', // 전체를 가로 정렬
          alignItems: 'center',
          gap: '1rem', // 텍스트와 버튼 간 간격
        }}
      >
        {withPDFButton()}
        <Button
          style={{
            color:
              props.taskShare == '' && props.submitTask.edit_time
                ? '#262C31'
                : '#FF9900',
            fontSize: '0.875rem',
            border:
              props.taskShare == '' && props.submitTask.edit_time
                ? '1.5px solid var(--gray-2, #262C31)'
                : '1.5px solid var(--gray-2, #FF9900)',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            if (props.taskShare) {
              props.resultEvent(props.submitTask.student_id, props.allTasks);
            } else {
              props.editEvent(props.submitTask.student_id, props.allTasks);
            }
          }}
        >
          {props.taskShare == '' ? '피드백 수정하기' : '공유한 결과 보기'}
        </Button>
      </Box>
    </Box>
  );
};
TaskEvalView.propTypes = {
  allTasks: PropTypes.array,
  submitTask: PropTypes.object,
  endTime: PropTypes.string,
  editEvent: PropTypes.func,
  resultEvent: PropTypes.func,
  taskShare: PropTypes.bool,
};
export default TaskEvalView;
