import React from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '32rem',
          textAlign: 'left',
          borderRadius: '0.625rem',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
            marginTop: '1.5rem',
            textAlign: 'center',
            color: '#FF9900',
          }}
        >
          그룹 코드 변경
        </Box>
        <Box style={{color: '#68737D', marginTop: '3rem'}}>
          그룹 코드를 변경하겠습니까?
        </Box>
        <Box style={{fontSize: '0.9rem'}}>
          그룹 코드를 변경해도 기존에 소속된 선생님은 계속 그룹에 소속되어
          있습니다.
        </Box>
        <Box style={{fontSize: '0.9rem'}}>
          신규로 그룹에 합류하는 선생님은 새로운 그룹코드를 입력하여야 합니다.
        </Box>

        <Box style={{marginTop: '3rem', display: 'flex'}}>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: ' #FF9900',
              backgroundColor: 'white',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
              border: '1px solid #FF9900',
            }}
            onClick={() => props.cancelEvent()}
          >
            취소
          </Button>
          <Button
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              color: 'white',
              backgroundColor: '#FF9900',
              fontSize: '1.125rem',
              fontWeight: 'bold',
              padding: '0.9rem 0rem',
              marginLeft: '1rem',
              borderRadius: '6.25rem',
            }}
            onClick={() => props.codeReset()}
          >
            변경하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  codeReset: PropTypes.func,
  cancelEvent: PropTypes.func,
};
export default ItemView;
