import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {StringUtils} from 'common/utils/StringUtils';

export const TaskEvalView = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '1.7rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
        <Box
          style={{
            display: 'flex',
            gap: '0.5rem',
            color: '#636C73',
            fontSize: '0.875rem',
            fontWeight: 'bold',
          }}
        >
          {StringUtils.getDateTimeKr(props.submitTask.submit_time) +
            (props.submitTask.submit_time == '' ? '' : ' | ')}
          {props.submitTask.student_name +
            (props.submitTask.class_name_list.length == 0 ? '' : ' | ')}
          {props.submitTask.class_name_list
            .sort((a, b) => {
              if (a > b) return 1;
              if (a < b) return -1;
              return 0;
            })
            .map((value, idx) => {
              let classBreak = '';
              if (idx != props.submitTask.class_name_list.length - 1) {
                classBreak = <Box style={{marginLeft: '0.5rem'}}>{' | '}</Box>;
              }
              return (
                <Box
                  key={'class' + idx}
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  <Box>{value}</Box>
                  {classBreak}
                </Box>
              );
            })}
        </Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {props.submitTask.title + ' (' + props.submitTask.score + '점, '}
          {props.submitTask.eval_try + '번 수정)'}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Button
          style={{
            color: '#262C31',
            fontSize: '0.875rem',
            border: '1.5px solid var(--gray-2, #E9EBED)',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            props.resultEvent(props.submitTask.student_id, props.allTasks);
          }}
        >
          {'중간 제출 확인'}
        </Button>
        <Box
          style={{
            display: props.submitTask.edit_time == '' ? 'none' : '',
            color: '#ff9900',
            fontWeight: 'bold',
          }}
        >
          {StringUtils.getDateString(props.submitTask.edit_time)}
        </Box>
      </Box>
    </Box>
  );
};
TaskEvalView.propTypes = {
  allTasks: PropTypes.array,
  submitTask: PropTypes.object,
  resultEvent: PropTypes.func,
  endTime: PropTypes.string,
  viewEvent: PropTypes.func,
};
export default TaskEvalView;
