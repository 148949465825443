import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React from 'react';

export const TaskView = props => {
  const dateLeft = StringUtils.getDateDiff(props.task.end_time);
  const startDateLeft = StringUtils.getDateDiff(props.task.start_time);
  const endTimeLeft = StringUtils.getTimeDiff(props.task.end_time);
  const totalStudents =
    props.task.overall_submission_rate.submit +
    props.task.overall_submission_rate.non_submit;
  const submitRate =
    totalStudents == 0
      ? 0
      : (
          (props.task.overall_submission_rate.submit / totalStudents) *
          100
        ).toFixed(0);
  function showLabel() {
    if (props.task.status == '진행중') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: 'white',
              backgroundColor: '#00C500',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{dateLeft}
          </Box>
          <Box
            style={{
              color: '#FF9900',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #D8DCDE',
            }}
          >
            진행중
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.status == '대기') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#EBEEF4',
              backgroundColor: '#35D2BB',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{startDateLeft}
          </Box>
          <Box
            style={{
              color: '#68737D',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #68737D',
            }}
          >
            대기
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
        </Box>
      );
    } else if (props.task.status == '종료') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#87929D',
              backgroundColor: '#ffe4e4',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            {endTimeLeft < 0 ? `D+${Math.abs(dateLeft)}` : `D-${dateLeft}`}
          </Box>
          <Box
            style={{
              display: props.task.task_share == 1 ? 'none' : '',
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            종료
          </Box>
          <Box
            style={{
              display:
                props.task.task_type == 1 && props.task.task_share != 1
                  ? ''
                  : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
              border: '1px solid #279EFF',
            }}
          >
            {'AI 피드백'}
          </Box>
          <Box
            style={{
              display: props.task.task_share == 1 ? '' : 'none',
              color: '#ff9900',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              border: '1px solid #ff9900',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            {'공유 완료'}
          </Box>
        </Box>
      );
    }
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        paddingBottom: '1rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
        <Box style={{display: 'flex', gap: '0.5rem'}}>{showLabel()}</Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {props.task.task_name}
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          {props.task.class_name_list
            .sort((a, b) => {
              if (a > b) return 1;
              if (a < b) return -1;
              return 0;
            })
            .map((value, idx) => {
              let classBreak = '';
              if (idx != props.task.class_name_list.length - 1) {
                classBreak = (
                  <Box
                    style={{
                      backgroundColor: '#A8B1B7',
                      width: '1px',
                      height: '0.5rem',
                      marginLeft: '0.5rem',
                    }}
                  />
                );
              }
              return (
                <Box
                  key={'class' + idx}
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  <Box
                    style={{
                      color: '#636C73',
                      fontSize: '0.875rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {value}
                  </Box>
                  {classBreak}
                </Box>
              );
            })}
        </Box>
        <Box
          style={{
            color: '#636C73',
            fontSize: '0.875rem',
            fontWeight: 'bold',
          }}
        >
          과제 마감 {props.task.end_time.slice(0, -3)} |{' '}
          {props.task.overall_submission_rate.submit}명 / {totalStudents}명 제출
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Button
          style={{
            display:
              props.task.status == '종료' || props.task.status == '대기'
                ? ''
                : 'none',
            color: '#B1B1B1',
            fontSize: '0.875rem',
            border: '1.5px solid #A8B1B7',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            marginRight: '1rem',
            padding: '0.5rem 1.5rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            props.removeEvent(props.task);
          }}
        >
          {'삭제'}
        </Button>
        <Box
          style={{
            display: props.task.status == '대기' ? 'none' : 'flex',
            flexDirection: 'column', // 수직 정렬
            justifyContent: 'center', // 수평 중앙 정렬
            alignItems: 'center', // 수직 중앙 정렬
            height: '100%', // 필요한 경우 부모 높이에 맞추기
          }}
        >
          <Box
            style={{
              width: '3.5rem',
              height: '3.5rem',
              borderRadius: '6.25rem',
              backgroundColor: '#ff9900',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '1.125rem',
            }}
          >
            {submitRate}%
          </Box>
          <Box>
            <Button
              style={{
                color: '#68737D',
                fontSize: '0.875rem',
                border: '1px solid var(--gray-2, #E6E8EB)',
                borderRadius: '0.625rem',
                marginTop: '0.5rem',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                props.detailEvent(props.task);
              }}
            >
              자세히 보기
            </Button>
          </Box>
        </Box>
        <Button
          style={{
            color: props.task.status == '종료' ? '#E9EBED' : '#35D2BB',
            display: props.task.status == '대기' ? '' : 'none',
            fontSize: '0.875rem',
            border:
              props.task.status == '종료'
                ? '1.5px solid var(--gray-2, #E9EBED)'
                : '1.5px solid var(--gray-2, #35D2BB)',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            props.detailEvent(props.task);
          }}
        >
          {props.task.status === '종료'
            ? '결과 확인'
            : props.task.status === '대기'
            ? '과제 내용 변경'
            : '과제 관리'}
        </Button>
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  task: PropTypes.object,
  detailEvent: PropTypes.func,
  removeEvent: PropTypes.func,
};
export default TaskView;
