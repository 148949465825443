import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState} from 'react';
import {StringUtils} from 'common/utils/StringUtils';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';
import TeacherDeletePopup from 'view/admin/TeacherDeletePopup';

export const TeacherView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [teacherDeleteVisibility, SetTeacherDeleteVisibility] = useState(false);
  function teacherDelete(admin_id, teacher_id) {
    SetTeacherDeleteVisibility(false);
    const getInfo = async () => {
      try {
        const response = await APIUtils.AdminTeacherDelete(
          admin_id,
          teacher_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        props.getTeacherInfo();
      }
    });
  }

  function teacherDeleteCancel() {
    SetTeacherDeleteVisibility(false);
  }
  const ID =
    props.teacher_info.login_id === null
      ? props.teacher_info.email
      : `${props.teacher_info.login_id} / ${props.teacher_info.login_password}`;
  return (
    <Box style={{width: '95%', marginLeft: '2rem', maxWidth: '120rem'}}>
      <TeacherDeletePopup
        visibility={teacherDeleteVisibility}
        admin_id={authReducer.user_id}
        teacher_id={props.teacher_info.user_id}
        confirmEvent={teacherDelete}
        cancelEvent={teacherDeleteCancel}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          paddingBottom: '0.7rem',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Box style={{flex: 1, fontSize: '0.825rem'}}>
          {props.teacher_info.name}
        </Box>
        <Box
          style={{
            flex: 2,
            fontSize: '0.825rem',
          }}
        >
          {ID}
        </Box>

        <Box style={{flex: 1, fontSize: '0.825rem'}}>
          {props.teacher_info.auth_key}
        </Box>
        <Box style={{flex: 1, fontSize: '0.825rem'}}>
          {props.teacher_info.student_num + '명'}
        </Box>
        <Box style={{flex: 1, fontSize: '0.825rem'}}>
          {StringUtils.getDateString(props.teacher_info.last_login_time)}
        </Box>
        <Box style={{flex: 1}}>
          <Button
            style={{
              display:
                authReducer.user_id == props.teacher_info.user_id ? 'none' : '',
              color: '#4b4d4c',
              border: '2px solid #4b4d4c',
              borderRadius: '1rem',
              fontSize: '0.825rem',
              fontWeight: '500',
              padding: '0.2rem 1rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              SetTeacherDeleteVisibility(true);
            }}
          >
            내보내기
          </Button>
          <Button
            style={{
              display:
                authReducer.user_id == props.teacher_info.user_id ? '' : 'none',
              color: '#00C500',
              backgroundColor: '#E6FAE6',
              border: '2px solid #00C500',
              borderRadius: '1.9rem',
              fontSize: '0.825rem',
              fontWeight: '500',
              padding: '0.2rem 1rem',
            }}
          >
            그룹관리자
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
TeacherView.propTypes = {
  getTeacherInfo: PropTypes.func,
  teacher_info: PropTypes.object,
};
export default TeacherView;
