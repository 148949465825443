import PropTypes from 'prop-types';
import {Box, Button, Checkbox} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState} from 'react';
import {OpenPDFUrl} from 'common/utils/FileUtils';
import APIUtils from 'common/utils/APIUtils';

const HistoryTableItem = props => {
  function getPDFButton() {
    if (props.last_pdf_url == '') {
      return (
        <Button
          style={{
            color: '#898989',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            border: '1px solid #898989',
            width: '8rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => props.makeEvent(props.eval_id)}
        >
          PDF 만들기
        </Button>
      );
    } else if (props.last_pdf_url == 'making') {
      return (
        <Button
          style={{
            color: 'white',
            fontSize: '0.875rem',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            backgroundColor: '#a8b1b7',
            width: '8rem',
          }}
        >
          PDF 생성 중
        </Button>
      );
    }
    return (
      <Box style={{display: 'flex', whiteSpace: 'nowrap', gap: '0.5rem'}}>
        <Button
          style={{
            color: '#279EFF',
            fontSize: '0.875rem',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            border: '1px solid #279EFF',
            width: '8rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => props.sendEvent(props.last_pdf_url)}
        >
          평가 리포트
        </Button>
        <Button
          style={{
            color: '#898989',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            border: '1px solid #898989',
            width: '8rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => props.makeEvent(props.eval_id)}
        >
          다시 만들기
        </Button>
      </Box>
    );
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #68737D',
        paddingBottom: '1rem',
        paddingTop: '1rem',
      }}
    >
      <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
        <Box>
          <Checkbox
            checked={props.checked}
            onChange={() => {
              props.handleCheck(props.index);
            }}
            inputProps={{'aria-label': 'controlled'}}
            style={{color: '#FF9900'}}
          />
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
          <Box
            style={{
              display: 'flex',
              color: '#636C73',
              fontSize: '0.875rem',
              fontWeight: 'bold',
            }}
          >
            {StringUtils.getDateString(props.eval_time)} {' | '}
            {props.student_name} {' | '}
            {props.writing_janre}
          </Box>
          <Box style={{fontWeight: 'bold'}}>
            {props.title} {'('}
            {parseFloat(props.score).toFixed(1)}
            {'점)'}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            justifyContent: 'end',
          }}
        >
          <Box
            style={{
              width: '8rem',
              textAlign: 'center',
              fontSize: '0.875rem',
              fontWeight: 'bold',
            }}
          >
            {StringUtils.getDateTimeString(props.last_modify_time)}
          </Box>
          <Button
            style={{
              backgroundColor: 'white',
              color: '#FF9900',
              borderRadius: '1.925rem',
              marginTop: '0.5rem',
              padding: '0.5rem 1rem',
              border: '1px solid #FF9900',
              width: '8rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              if (props.disabled) {
                props.noTicketEvent();
              } else {
                props.modifyEvent(props.eval_id);
              }
            }}
            // disabled={props.disabled}
          >
            수정하기
          </Button>
        </Box>
        {getPDFButton()}
      </Box>
    </Box>
  );
};
export default HistoryTableItem;
HistoryTableItem.propTypes = {
  makeEvent: PropTypes.func,
  sendEvent: PropTypes.func,
  modifyEvent: PropTypes.func,
  noTicketEvent: PropTypes.func,
  handleCheck: PropTypes.func,
  eval_id: PropTypes.number,
  title: PropTypes.string,
  student_age: PropTypes.string,
  eval_time: PropTypes.string,
  last_modify_time: PropTypes.string,
  student_name: PropTypes.string,
  writing_janre: PropTypes.string,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  last_pdf_url: PropTypes.string,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};
